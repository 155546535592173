import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {useMobileView} from '../../../styles/helper';
import {setFocus} from '../../../redux/slices/appSlice';
import './styles.scss';

const BackButton = props => {
  const dispatch = useDispatch();
  const mobileView = useMobileView();

  const handleBackClick = useCallback(() => {
    if(props.onClick) {
      props.onClick();
    }
    if(!props.ignoreDefaultHandler) {
      dispatch(setFocus({target: 'desk', params: {}}));
    }
  }, [dispatch, props]);
  return (<button className="back-button" onClick={handleBackClick}><img src={require('../../../assets/img/icon-arrow-back.png')} alt="" />{`${mobileView ? '' : (props.overrideText || 'Back')}`}</button>)
};

export default BackButton;