import Monitor from '../../Elements/DeviceWrappers/Monitor';

import './styles.scss';

const Startup = () => {
  return (
    <Monitor hideBack={true}>
      <div className="shutdown-wrapper">
        <p>Logging in...</p>
        <div className="loader"></div>
      </div>
    </Monitor>
  )
};

export default Startup;
