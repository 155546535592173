const advice = [
  {
    id: 'af78bbb4-e503-4cf7-9916-3133fb39ada4',
    label: 'impersonation_advice',
    title: 'CEO Impersonation scam',
    text: 'In impersonation scams, a criminal impersonates your boss, a senior manager or a colleague to either make an urgent payment or change payment details for a contract or supplier.</p><p>They gain access to your business’s email account by hacking or use spoofing software to email a member of the finance team with what appears to be a genuine email from your boss or a senior manager They can even fake their image or voice on a call.</p><p>Criminals may target businesses over several months, building a picture of the structure of your firm and the employees responsible for authorising payments.</p><p>Your website can sometimes reveal information about genuine suppliers that can then be used by criminals.</p><h3>HOW TO SPOT AN IMPERSONATION SCAM</h3><ul><li>You’re asked to urgently process an unusual payment by your CEO, director, senior manager or a colleague.</li><li>The language used in the email isn’t consistent with that of the genuine sender.</li><li>The person becomes unreasonably angry or upset if you insist on following the rules.</li><li>You’re asked to do something against the rules, particularly related to IT security.</li><li>You’re contacted through an unusual or personal medium – in a smaller organisation you may often be contacted via personal emails, but if that is unusual, look for changes.</li></ul><h3>ALWAYS REMEMBER</h3><ul><li>Confirm urgent payment requests directly with the sender in person or over the phone, or through some other independent means.</li><li>Be wary of unexpected emails or letters requesting urgent payment, even if it appears to be from someone in your own business.</li><li>If you have visited a website you think is suspicious you can report it to the authorities.</li></ul>',
  },
  {
    id: 'eb7b90d2-6ba4-4523-bef7-b1cffb33740d',
    label: 'gift_card_advice',
    title: 'Gift card scam',
    text: '<p>Gift card scams are increasingly common. All a fraudster requires is the gift card number and they check regularly for any money put onto the cards. Once they see money, they use the code to buy goods or offers or even convert it back to cash.</p><p>Often the fraudster simply grabs multiple gift cards in a shop, quickly scans the numbers (a competent scanner can cover over 100 cards in less than an hour) and puts them back.</p><p>Alternatively, they encourage companies to purchase them and either take any money on the cards or scan the numbers and return the cards to the company, so that they have no knowledge of the scam.</p>',
  },
  {
    id: '79a3bf23-697c-4e78-9127-c55c47bfdd68',
    label: 'it_takeover_advice',
    title: 'IT takeover scam',
    text: '<p>Giving someone access to your computer, phone or other devices gives them access to all the data on the system, as well as all the services you use from that system (like invoicing or banking systems you are logged into).</p><p>It can take less than 30 seconds for someone with access to your computer to install software that gives them total control, even when you think your computer is off.</p><p>It is very important to be sure you are handing over control to the right person – someone genuine. Whether this is your organisation’s corporate IT department, if you have one, or an outside consultant, you should follow the Take Five to Stop Fraud advice and take a moment to:</p><h3>STOP, CHALLENGE and PROTECT:</h3><ul><li>Never give full PINs, passwords or multi-factor authentication codes to anyone - even your IT department – they should never need them.</li><li>Most of the time, anyone taking control of your computer already has the software on your device that they need, so if you are asked to download and install a new app or link, consider it higher risk and take extra care. </li><li>If someone knows information about your device, such as a unique ID, the name of a user on the system, or the make of a piece of hardware, that is not proof that they are the real deal. It is often very easy to discover this information.</li><li>It\'s always best to stop and check with your IT contact by emailing them directly, or by calling or texting them via details you already have.</li><li>Just because the email is coming from an official-looking email address, this doesn\'t mean it\'s real. Email addresses can be \'spoofed\' which means they look like a real address even when they\'re sent by someone else.</li></ul><p>All of this advice applies to your corporate IT but also to your personal devices too.</p>',
  },
  {
    id: '2b6a9f6d-8e90-4cf0-8615-1b834687f3d1',
    label: 'detail_change_advice',
    title: 'Account change scam',
    text: '<p>These scams happen when criminals pose as a regular supplier and persuade you to change the bank account details you hold on file. You’re then tricked into sending money to the account which is controlled by a criminal rather than the genuine supplier.</p><p>Criminals carry out extensive research about your business to find out who your suppliers are and when regular payments are due. These scams often involve a criminal intercepting emails, gaining access to your supplier’s email account or spoofing their emails.</p><p>The fraud is often only discovered when the legitimate supplier of the product or service chases for non-payment. At that point recovery of the funds from the fraudulent account is very difficult.</p><h3>How to spot an invoice and mandate scam:</h3><ul><li>You receive a request out of the blue to change the bank details of an existing supplier.</li><li>You receive more frequent than usual or duplicate invoices for a product or service.</li></ul>',
  }
]

export default advice;