import Monitor from '../../Elements/DeviceWrappers/Monitor';

import './styles.scss';

const PayInvoice = props => {
  return (
    <Monitor hideBack={true}>
      <div className="finance-window">

      </div>
    </Monitor>
  );
};

export default PayInvoice;