import {useState, useEffect} from 'react';
import {useMediaQuery} from 'react-responsive'

export const useMobileView = () => useMediaQuery({query: 'screen and (max-width: 1066px) and (orientation: landscape)'});

export const useWindowSize = () => {

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export const useContainerSize = () => {

  const [containerSize, setContainerSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      const width = (window.innerWidth > 1.5 * window.innerHeight)  ? 1.5 * (window.innerHeight - 96) : window.innerWidth - 96;
      const height = (window.innerWidth > 1.5 * window.innerHeight) ? window.innerHeight - 96 : (2/3) * (window.innerWidth - 96);
      setContainerSize({
        width,
        height,
      });
    }

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return containerSize;
}