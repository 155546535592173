import {useSelector} from 'react-redux';
import {narrativeSelectors} from '../../redux/slices/narrativeSlice';
import IntroScreen from '../Events/IntroScreen';
import OnePager from '../Events/OnePager';
import RobertaCall from '../Events/RobertaCall';
import BankTransfer from '../Events/BankTransfer';
import LunchNote from '../Events/LunchNote';
import LunchNoteBlank from '../Events/LunchNoteBlank';
import OptionsList from '../Elements/OptionsList';
import SearchResult from '../Events/SearchResult';
import CreateOrder from '../Events/CreateOrder';
import Shutdown from '../Events/Shutdown';
import Startup from '../Events/Startup';
import VoucherPurchase from '../Events/VoucherPurchase';
import PayInvoice from '../Events/PayInvoice';
import ITTakeover from '../Events/ITTakeover';
import AccountChange from '../Events/AccountChange';
import ProcureOld from '../Events/ProcureOld';
import ProcureNew from '../Events/ProcureNew';
import Summary from '../Events/Summary';

import './styles.scss';

const Event = props => {
  const events = useSelector(narrativeSelectors.getCurrentEvents);
  const event_map = {
    show_intro_screen: IntroScreen,
    show_one_pager_full_screen: OnePager,
    show_roberta_unanswered_call: RobertaCall,
    show_wazt_payment_screen: BankTransfer,
    show_go_to_lunch: LunchNoteBlank,
    show_back_from_lunch: LunchNote, 
    show_wazt_search_results: SearchResult,
    create_clambones_order: CreateOrder,
    day_1_ends: Shutdown,
    day_2_starts: Startup,
    voucher_purchase: VoucherPurchase,
    open_finance_software: PayInvoice,
    it_takeover: ITTakeover,
    day_2_ends: Shutdown,
    day_3_starts: Startup,
    account_change: AccountChange,
    procure_new: ProcureNew,
    procure_old: ProcureOld,
    summary: Summary,
  };

  

  return (<>
    {events.map(event => {
      const EventComponent = Object.keys(event_map).includes(event.label) ? event_map[event.label] : null;
      
      return (<div key={event.label} className="events-wrapper">
        {EventComponent !== null ? <EventComponent key={event.label} /> : event.label}
        <OptionsList  advice={event.advice}  options={event.options} />
      </div>)
    })}
  </>);
};

export default Event;