import {useState, useRef, useEffect, useMemo, useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import classNames from 'classnames';
import OptionsList from '../Elements/OptionsList';
import Phone from '../Elements/DeviceWrappers/Phone';
import {Scrollbar} from 'react-scrollbars-custom';
import {narrativeSelectors, markMessagesReadByContact} from '../../redux/slices/narrativeSlice';

import './styles.scss';

const Message = props => {
  const dispatch = useDispatch();
  const [selectedContact, setSelectedContact] = useState(props && props.params && props.params.contact);
  const messages = useSelector(narrativeSelectors.getCurrentMessages);
  const unreadMessageCount = useSelector(narrativeSelectors.getUnreadMessageCount);
  const messageTimer = useRef(null);
  const contactList = useMemo(() => {
    return [...new Set(messages.map(message => message.contact))];
  }, [messages]);
  const scrollRef = useRef(null);


  useEffect(() => {
    if(selectedContact && unreadMessageCount.byContact[selectedContact] > 0) {
      clearTimeout(messageTimer.current);
      setTimeout(() => {
        dispatch(markMessagesReadByContact({contact: selectedContact}))
      }, 1000);
    }
  }, [dispatch, messages, selectedContact, unreadMessageCount]);

  useEffect(() => {
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  }, [messages]);

  const handleContactClick = useCallback((contact) => {
    if(contactList.includes(contact)) {
      setSelectedContact(contact);
    }
  }, [contactList, setSelectedContact]);

  const handleCloseMessages = useCallback(() => {
    setSelectedContact(undefined);
  }, [setSelectedContact]);

  return (<Phone hideBack={true}>
    
      <div className="message-pane">
        <div className="contact-inner">
          
          {!selectedContact && (<>
            <h2 className="message-title">Messages</h2>
            <ul className="contact-list">
                {contactList && contactList.map((contact, index) => {
                  const contactClasses = classNames('contact-item',
                    {
                      active: selectedContact === contact
                    }
                  )
                  return (<li key={`contact-${index}`} onClick={() => handleContactClick(contact)} className={contactClasses}><span className="contact-person"><img alt="" src={require('../../assets/img/icon-avatar.png')} />{contact}</span>{unreadMessageCount.byContact && unreadMessageCount.byContact[contact] && <span className="messageCount">{unreadMessageCount.byContact[contact]}</span>}</li>);
                })}
              </ul>
            </>)}
            {selectedContact &&  (<>
              <h2 onClick={handleCloseMessages} className="message-title">{selectedContact}</h2>
              
                <Scrollbar ref={scrollRef} style={{height: '86%', width: '100%'}}>
                  <ul className='message-list'>
                {messages.filter(message => message.contact === selectedContact).map((message, index) => {
                  const messageClasses = classNames('message-item',
                  {
                    outgoing: message.direction === 'outgoing',
                    incoming: message.direction === 'incoming',
                  })
                  return (<li key={`message-${index}`} className={messageClasses}>
                            <div className="message-body" dangerouslySetInnerHTML={{__html: message.message}} />
                            {/* <OptionsList options={message.options} /> */}
                          </li>) 
                })}
                  </ul>
                </Scrollbar>
              
            </>)}
          </div>
      </div>
      {selectedContact && messages.filter(message => message.contact === selectedContact).length > 0 && messages.filter(message => message.contact === selectedContact)[messages.filter(message => message.contact === selectedContact).length  - 1] && (
        <OptionsList advice={messages.filter(message => message.contact === selectedContact)[messages.filter(message => message.contact === selectedContact).length  - 1].advice} options={messages.filter(message => message.contact === selectedContact)[messages.filter(message => message.contact === selectedContact).length  - 1].options} />
      )}
  </Phone>);
};

export default Message;