import {useCallback, useEffect, useState} from 'react';
import './styles.scss';

const Subtitles = ({onChange, onInit, subtitles}) => {
  const [currentSubtitleIndex, setCurrentSubtitleIndex] = useState(0);

  const updateCurrentSubtitleIndex = useCallback((change) => {
    const newSubtitleIndex = currentSubtitleIndex + change;
    if(newSubtitleIndex >= 0 && newSubtitleIndex < subtitles.length) {
      setCurrentSubtitleIndex(newSubtitleIndex);
      if(onChange) {
        onChange(newSubtitleIndex);
      }
    }
  }, [currentSubtitleIndex, subtitles, onChange]);

  useEffect(() => {
    setCurrentSubtitleIndex(0);
    onInit();
  }, [onInit, subtitles]);
  
  if(currentSubtitleIndex >= subtitles.length) return null;

  return (
    <div className="subtitle-wrapper">
      {subtitles[currentSubtitleIndex].speaker ? <h3 className="subtitle-speaker">{subtitles[currentSubtitleIndex].speaker}</h3> : null}
      <p className="subtitle-text">{subtitles[currentSubtitleIndex].text}</p>
      <div className="button-wrapper"><button onClick={() => updateCurrentSubtitleIndex(-1)} disabled={currentSubtitleIndex > 0 ? '' : 'disabled'}>Back</button></div>
      <div className="button-wrapper"><button onClick={() => updateCurrentSubtitleIndex(1)} disabled={currentSubtitleIndex === subtitles.length - 1 ? 'disabled' : ''}>Next</button></div>
    </div>
  );
};

export default Subtitles;