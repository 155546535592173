import {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {narrativeSelectors, removeFlags, setFlags, resetAllFlags} from '../../redux/slices/narrativeSlice';
import {resetApp} from '../../redux/slices/appSlice';

import './styles.scss';

const Flags = props => {
  const [newFlag, setNewFlag] = useState('');
  const flags = useSelector(narrativeSelectors.getSetFlags);
  const dispatch = useDispatch();

  const handleNewFlagInput = useCallback((event) => {
    setNewFlag(event.target.value);
  }, [setNewFlag]);

  const handleNewFlagSubmit = useCallback(() => {
    if(newFlag.length > 0)  {
      dispatch(setFlags([newFlag]));
    }
  }, [newFlag, dispatch]);

  const handleRemoveFlag = useCallback((flag) => {
    dispatch(removeFlags([flag]));
  }, [dispatch]);

  const handleResetSubmit = useCallback(() => {
    dispatch(resetAllFlags());
    dispatch(resetApp());
  }, [dispatch]);

  return(<div className='flag-admin-wrapper'>
    <h1>Flags</h1>
    <p>This is a debugging tool and will not be visible in production</p>
    <p>{`Version ${process.env.REACT_APP_NAME}-${process.env.REACT_APP_VERSION}`}</p>
    <ol className="flag-list">
      {flags && flags.map((flag, index) => (<li key={`flag-${index}`}>{flag}<button onClick={() => handleRemoveFlag(flag)}>Remove</button></li>))}
    </ol>
    <div>
      <input value={newFlag} onChange={handleNewFlagInput} />
      <button onClick={handleNewFlagSubmit} disabled={newFlag.length < 0 ? 'disabled' : ''}>Set</button>
      <button onClick={handleResetSubmit}>Reset All</button>
    </div>
  </div>);
};

export default Flags;