import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
// import expireReducer from 'redux-persist-expire';           // TO DO SETUP EXPIRE FOR REDUCERS
import appReducer from './slices/appSlice';
import narrativeReducer from './slices/narrativeSlice';

const middleware = process.env.NODE_ENV === 'production' ? [thunk] : [thunk, logger];

const rootPersistConfig = {
    key: `${process.env.REACT_APP_NAME}-${process.env.REACT_APP_VERSION}`,
    storage,
};


const rootReducer = combineReducers({
  app: appReducer,
  narrative: narrativeReducer,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer)


export const store = configureStore({
    reducer: persistedReducer, 
    middleware: middleware
});

export const persistor = persistStore(store);
