import {useState, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {narrativeSelectors} from '../../redux/slices/narrativeSlice';
import Subtitles from '../Elements/Subtitles';
import OptionsList from '../Elements/OptionsList';

import './styles.scss';

const teabreakImages = {
  cat_1: require('../../assets/img/cat_1.jpg'),
  cat_2: require('../../assets/img/cat_2.jpg'),
  cat_3: require('../../assets/img/cat_3.jpg'),
};

const TeaBreak = (props) => {
  const [currentSubtitle, setCurrentSubtitle] = useState(0);
  const currentTeabreaks = useSelector(narrativeSelectors.getCurrentTeabreak);

  const handleSubtitlesInit = useCallback(() => {
    setCurrentSubtitle(0);
  }, []);

  const handleSubtitlesChanged = useCallback((subtitleIndex) => {
    setCurrentSubtitle(subtitleIndex);
  }, [setCurrentSubtitle])

  return (
    <div className="teabreak-wrapper">
      <div className="teabreak-controls">
        <Subtitles onInit={handleSubtitlesInit} onChange={handleSubtitlesChanged} subtitles={currentTeabreaks && currentTeabreaks.length && currentTeabreaks.length >= 1 && currentTeabreaks[0].subtitles ? currentTeabreaks[0].subtitles : []} />
        {!!(currentTeabreaks && currentTeabreaks.length && currentTeabreaks.length >= 1 && currentTeabreaks[0].subtitles && currentSubtitle  && currentSubtitle === currentTeabreaks[0].subtitles.length - 1) && <OptionsList advice={currentTeabreaks[0].advice} options={currentTeabreaks[0].options} />}
        {currentTeabreaks && currentTeabreaks.length && currentTeabreaks[0].subtitles.length >= currentSubtitle && currentTeabreaks[0].subtitles[currentSubtitle].images.length ? (
          (<div className='teabreak-images-wrapper'>
            {currentTeabreaks && currentTeabreaks.length && currentTeabreaks.length >= 1 && currentTeabreaks[0].subtitles ? currentTeabreaks[0].subtitles[currentSubtitle].images.map(image => <img src={teabreakImages[image]} alt="" />
            ) : null}
          </div>)
        ) : null}
      </div>
    </div>
  );
};

export default TeaBreak;