const messages = [
  {
    id: '91796907-2028-40b8-beda-f75ea03c4be4',
    direction: 'outgoing',
    contact: 'Roberta',
    time: {
      hours: 9,
      minutes: 12,
    },
    requiredCounters: [],
    requiredFlags: [['VOICEMAIL_LOGIN_REFUSE']],
    message: 'Sorry, I can\'t share login details. Why don\'t you see if IT can help?',
    options: [
      {
        label: 'Send',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['VOICEMAIL_LOGIN_REFUSE_SENT'],
        hideFlags: [['VOICEMAIL_LOGIN_REFUSE_SENT']],
      }
    ],
  },
  {
    id: '0b3fc642-5a6f-4f7e-ab6e-ee46b7790234',
    direction: 'incoming',
    contact: 'Roberta',
    time: {
      hours: 9,
      minutes: 13,
    },
    requiredCounters: [],
    requiredFlags: [['VOICEMAIL_LOGIN_REFUSE_SENT']],
    message: 'It\'s just this once. Really urgent - please just send.',
    options: [
      {
        label: 'Send login details',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['VOICEMAIL_LOGIN_AGREE'],
        hideFlags: [['VOICEMAIL_LOGIN_REFUSE_CONFIRM'], ['VOICEMAIL_LOGIN_AGREE']],
      },
      {
        label: 'Refuse to send details',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['VOICEMAIL_LOGIN_REFUSE_CONFIRM'],
        hideFlags: [['VOICEMAIL_LOGIN_REFUSE_CONFIRM'], ['VOICEMAIL_LOGIN_AGREE']],
      }
    ],
    advice: {
        adviceLabel: 'impersonation_advice'
    }
  },
  {
    id: 'ccfd82f5-6a4a-437a-898d-0b4ccc2d3348',
    direction: 'outgoing',
    contact: 'Roberta',
    time: {
      hours: 9,
      minutes: 12,
    },
    requiredCounters: [],
    requiredFlags: [['VOICEMAIL_LOGIN_REFUSE_CONFIRM']],
    message: 'Sorry Roberta.  I can message IT for you though',
    options: [
      {
        label: 'Send',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['VOICEMAIL_LOGIN_REFUSE_CONFIRM_SENT'],
        hideFlags: [['VOICEMAIL_LOGIN_REFUSE_CONFIRM_SENT']],
      }
    ],
  },
  {
    id: '0b3fc642-5a6f-4f7e-ab6e-ee46b7790234',
    direction: 'incoming',
    contact: 'Roberta',
    time: {
      hours: 9,
      minutes: 13,
    },
    requiredCounters: [],
    requiredFlags: [['VOICEMAIL_LOGIN_REFUSE_CONFIRM_SENT']],
    message: 'This is very disappointing. Frankly, you\'re on thin ice. I\'m going to give you one more chance to send me what I\'ve asked for.',
    options: [
      {
        label: 'Send login details',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['VOICEMAIL_LOGIN_AGREE'],
        hideFlags: [['VOICEMAIL_LOGIN_AGREE'], ['VOICEMAIL_LOGIN_REFUSE_IGNORE']],
      },
      {
        label: 'Ignore this message',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['VOICEMAIL_LOGIN_REFUSE_IGNORE'],
        hideFlags: [['VOICEMAIL_LOGIN_AGREE'], ['VOICEMAIL_LOGIN_REFUSE_IGNORE']],
      }
    ],
    advice: {
        adviceLabel: 'impersonation_advice'
    }
  },
  {
    id: 'c7153a14-ce4f-4113-bca7-32e21eda946e',
    direction: 'outgoing',
    contact: 'Roberta',
    time: {
      hours: 9,
      minutes: 12,
    },
    requiredCounters: [],
    requiredFlags: [['VOICEMAIL_LOGIN_AGREE']],
    message: 'Hi Roberta, the login is<br />username: banking@widgets.biz<br/>password:@w1dg3ts@',
    options: [
      {
        label: 'Send',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['VOICEMAIL_LOGIN_AGREE_SENT'],
        hideFlags: [['VOICEMAIL_LOGIN_AGREE_SENT']],
      }
    ],
  },
  {
    id: '433a24ed-6a40-4b58-bbfa-0ccef4f78ec5',
    direction: 'incoming',
    contact: 'Roberta',
    time: {
      hours: 9,
      minutes: 13,
    },
    requiredCounters: [],
    requiredFlags: [['VOICEMAIL_LOGIN_AGREE_SENT']],
    message: 'Thanks',
    options: [],
  },
];

export default messages;
