import {useSelector} from 'react-redux';
import {narrativeSelectors} from '../../../redux/slices/narrativeSlice';

import './styles.scss';

const backgroundImages = {
  bg_desktop_day_1_am: require('../../../assets/img/bg-desktop-day-1-am.jpg'),
  bg_desktop_day_1_pm: require('../../../assets/img/bg-desktop-day-1-pm.jpg'),
  bg_desktop_day_2_am: require('../../../assets/img/bg-desktop-day-2-am.jpg'),
  bg_desktop_day_2_pm: require('../../../assets/img/bg-desktop-day-2-pm.jpg'),
  bg_desktop_day_3_am: require('../../../assets/img/bg-desktop-day-3-am.jpg'),
  bg_desktop_day_3_pm: require('../../../assets/img/bg-desktop-day-3-pm.jpg'),
};

const Shutdown = () => {
  const backgroundData = useSelector(state => narrativeSelectors.getCurrentBackground(state, 'default'));

  return (
    <div className="shutdown-desk-wrapper">
      <img alt="" src={(backgroundImages && backgroundData && backgroundData.length) ? backgroundImages[backgroundData[0].backgroundIndex] : null} />
      <div className="shutdown-wrapper">
        <p>Shutting down...</p>
        <div className="loader"></div>
      </div>
    </div>
  )
};

export default Shutdown;
