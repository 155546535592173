import {useDispatch} from 'react-redux';
import {setFocus} from '../../../redux/slices/appSlice';
import './styles.scss';

const OnePagerLink = props => {
  const dispatch = useDispatch();

  return (<div className="one-pager-link-wrapper">
    <button onClick={() => dispatch(setFocus({target: 'onepager', params: {}}))}>
      <img alt="Document icon" src={require('../../../assets/img/icon-document.png')} />
      Read the fraud 1-pager
    </button>
  </div>);
};

export default OnePagerLink;