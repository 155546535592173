import {useCallback, useRef, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Typewriter from 'typewriter-effect';
import {setFlags} from '../../../redux/slices/narrativeSlice';
import Monitor from '../../Elements/DeviceWrappers/Monitor';

import './styles.scss';

const stages = [
  'from',
  'to',
  'date',
  'sort',
  'account',
  'amount'
];

const BankTransfer = props => {
  const dispatch = useDispatch();
  const typingTimer = useRef(null);
  const [currentStage, setCurrentStage] = useState(0);

  const incrementStageHandler = useCallback(() => {
    setCurrentStage(previous => {
      if(typingTimer.current && previous === stages.length -1) {
        clearInterval(typingTimer.current);
      }
      return previous + 1;
    });
  }, []);

  useEffect(() => {
    if(!typingTimer.current) {
      typingTimer.current = setInterval(incrementStageHandler, 1200);
    }
    return () => {
      if(!typingTimer.current) {
        clearInterval(typingTimer.current);
      }
    }
  }, [incrementStageHandler]);

  const handleConfirmClick = () => {
    dispatch(setFlags(['WAZT_PAYMENT_SENT']));
  };
  
  return(<Monitor hideBack={true}>
    <div className="bank-transfer-window">
      <div className="from-text">
       {currentStage === stages.findIndex(stage => stage === 'from') ?  <Typewriter options={{
                strings: 'Fine Widgets Ltd.',
                autoStart: true,
                loop: false,
                delay: 35,
              }} /> : null }
       {currentStage > stages.findIndex(stage => stage === 'from') ?  (<p>Fine Widgets Ltd.</p>) : null }
      </div>
      <div className="to-text">
        {currentStage === stages.findIndex(stage => stage === 'to') ? <Typewriter options={{
                strings: 'WAZT Securities Ltd.',
                autoStart: true,
                loop: false,
                delay: 35,
              }} /> : null }
        {currentStage > stages.findIndex(stage => stage === 'to') ?  (<p>WAZT Securities Ltd.</p>) : null }
      </div>
      <div className="payment-date">
        {currentStage === stages.findIndex(stage => stage === 'date') ? <Typewriter options={{
                strings: 'Immediate',
                autoStart: true,
                loop: false,
                delay: 35,
              }} /> : null }
        {currentStage > stages.findIndex(stage => stage === 'date') ?  (<p>Immediately</p>) : null }
      </div>
      <div className="sort-code">
        {currentStage === stages.findIndex(stage => stage === 'sort') ? <Typewriter options={{
                strings: '16-04-60',
                autoStart: true,
                loop: false,
                delay: 35,
              }} /> : null }
        {currentStage > stages.findIndex(stage => stage === 'sort') ?  (<p>16-04-60</p>) : null }
      </div>
      <div className="account-number">
        {currentStage === stages.findIndex(stage => stage === 'account') ? <Typewriter options={{
                strings: '98940303',
                autoStart: true,
                loop: false,
                delay: 35,
              }} /> : null }
        {currentStage > stages.findIndex(stage => stage === 'account') ?  (<p>98940303</p>) : null }
      </div>
      <div className="amount">
        {currentStage === stages.findIndex(stage => stage === 'amount') ? <Typewriter options={{
                strings: '£7500.00',
                autoStart: true,
                loop: false,
                delay: 35,
              }} /> : null }
        {currentStage > stages.findIndex(stage => stage === 'amount') ?  (<p>£7500.00</p>) : null }
      </div>
      {currentStage === stages.length ? (<button onClick={handleConfirmClick} className="bank-transfer-confirm">Confirm payment</button>) : null}
    </div>
  </Monitor>);
};

export default BankTransfer;
