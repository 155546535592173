import {useState, useCallback, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Scrollbar} from 'react-scrollbars-custom';

import BackButton from '../../Elements/BackButton';
import {setFlags, narrativeSelectors} from '../../../redux/slices/narrativeSlice';
import {appSelectors, hideOnePagerLink, setFocus, showOnePagerLink} from '../../../redux/slices/appSlice';
import './styles.scss';
import ScamHeader from '../../Elements/ScamHeader';

const OnePager = props => {
  const dispatch = useDispatch();

  const [scrolled, setScrolled] = useState(false);
  const readFlagSet = useSelector(state => narrativeSelectors.checkFlagSet(state, 'ONE_PAGER_READ'));
  const previousFocus = useSelector(appSelectors.getPreviousFocusTarget);
  const handleBackClick = useCallback(() => {
    if(!readFlagSet) {
      dispatch(setFlags(['ONE_PAGER_READ']));
    } else {
      dispatch(setFocus({
        target: previousFocus,
        params: {}
      }));
    }
  }, [dispatch, previousFocus, readFlagSet]);

  useEffect(() => {
    dispatch(hideOnePagerLink());
    return () => dispatch(showOnePagerLink())
  }, [handleBackClick, dispatch,]);

  const handleScroll = useCallback(() => {
    setScrolled(true);
  }, []);

  return (
    <div className="one-pager-wrapper">
      <div className="one-pager-overlay" />
      <div className="one-pager-inner-wrapper">
      <Scrollbar onScroll={handleScroll} noScrollX={true} style={{height: '100%', width: '100%'}}>
        <ScamHeader shadow={false} text="To stop fraud" />
        <div className="one-pager-content">
          <p>Criminals will often rely on employees in small and medium-sized businesses having little or no knowledge about fraud and scams and use this vulnerabilities. Employees may be short on time or too rushed to check whether a request for information, payment or a change of details is genuine, or use less secure IT devices, which makes it easier for them to fall for a fraud.</p>
          <h2>Keep your business safe by following these three steps:</h2>
          <h3>STOP</h3>
          <p>If you receive a request to make an urgent payment, change supplier bank details or provide financial information, take a moment to stop and think.</p>
          <h3>CHALLENGE?</h3>
          <p>Could it be fake? Verify higher risk payments and supplier details directly with the company on a known phone number or in person first.</p>
          <h3>PROTECT</h3>
          <p>Contact your business’s bank immediately if you think you’ve been scammed and report it to the organisation responsible for tackling fraud in your area.</p>
          <p><strong>More than four in five small business owners receive unsolicited texts or email requests. But only half of small business owners stop and think if an unsolicited email or text is genuine. Do you?  And do you know what to do to find out?</strong></p>
        </div>
        {!scrolled && <div className="scroll-prompt"><span className="ca3-scroll-down-arrow"></span></div>}
        </Scrollbar>
      </div>
      <BackButton overrideText="Close" ignoreDefaultHandler={true} onClick={handleBackClick} />
    </div>
  );
}

export default OnePager;