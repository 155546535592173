import classNames from 'classnames';
import './styles.scss';

const FocusSwitcher = props => {
  const {screenMap, focus, callBack} = props;

  return (
    <div className="focus-switcher-wrapper">
    <h1>Focus switcher</h1>
    <p>This is a debugging tool and will not be visible in production</p>
      <ul>
        {Object.keys(screenMap).map(screen => {
          const classes = classNames('focus-item', {
            active: focus && focus.target && focus.target === screen
          });
          return (<li key={screen} className={classes}>
            <button onClick={() => callBack(screen)}>{screen}</button>
          </li>)
        })}
      </ul>
    </div>
  );
}

export default FocusSwitcher;