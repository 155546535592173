import {useCallback} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {appSelectors, toggleMute} from '../../../redux/slices/appSlice';

import './styles.scss';

const mutedIcon = require('../../../assets/img/icon-muted.png');
const unmutedIcon = require('../../../assets/img/icon-unmuted.png');

const MuteButton = props => {
  const muted = useSelector(appSelectors.getMuted);
  const dispatch = useDispatch();

  const handleMuteClick = useCallback(() => {
    dispatch(toggleMute());
  }, [dispatch]);

  return (<button onClick={handleMuteClick} className="mute-button">
    <img alt="mute button" src={!!muted ? mutedIcon : unmutedIcon} />
  </button>);
};

export default MuteButton;