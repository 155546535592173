import {useRef, useCallback, useState, useEffect} from 'react';
import Typewriter from 'typewriter-effect';
import {useMobileView} from '../../../styles/helper';
import Monitor from '../../Elements/DeviceWrappers/Monitor';

import './styles.scss';

const SearchResult = () => {
  const timerRef = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const mobileView = useMobileView();

  const showResultsHandler = useCallback(() => {
    setShowResults(true);
  }, []);

  useEffect(() => {
    if(timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(showResultsHandler, 5000);

    return () => {
      if(timerRef.current) clearTimeout(timerRef);
    };
  }, [showResultsHandler]);

  return (<Monitor hideBack={true}>
    <div className="browser-wrapper search">
      {showResults ? null : (<div className="browser-inner">
          <img alt="search logo" className="search-logo" src={require('../../../assets/img/logo-search.jpg')} />
          <div className="search-input-wrapper">
            <Typewriter
              options={{
                strings: 'WAZT Securities Ltd',
                autoStart: true,
                loop: false,
              }}
            />
          </div>
        </div>)}
        {!showResults ? null : (<div className="browser-inner">
            <img alt="search results about wasps" className="results-image" src={require(mobileView ? '../../../assets/img/search-result-mobile.jpg': '../../../assets/img/search-result.jpg')} />
        </div>)}
      </div>
  </Monitor>);
};

export default SearchResult;