const events = [
  {
    "id": "86ac7a98-846d-4a6e-bbe9-063a745a9f3b",
    "label": "show_intro_screen",
    "requiredFlags": [],
    "hideFlags": [["GAME_STARTED"]]
  },
  {
    id: '23d0ce36-3e3c-4a7c-8c1b-4205aabb3a38',
    label: 'show_one_pager_full_screen',
    requiredFlags: [['INTRO_EMAIL_TRIGGERED'], ['EMAIL_TRIGGERED_cb9da670-76e9-47f5-ab84-e4b8cd334d99']],
    hideFlags: [['ONE_PAGER_READ']],
    options: []
  },
  {
    id: '94783631-8c74-45a8-98b9-47b730d5b917',
    label: 'show_roberta_unanswered_call',
    requiredFlags: [['WAZT_PAYMENT_CALL']],
    hideFlags: [['WAZT_PAYMENT_CALL_COMPLETE']],
    options: [
      {
        label: 'Hang up',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['WAZT_PAYMENT_CALL_COMPLETE'],
      },
    ]
  },
  {
    id: '03368e17-ffeb-429b-9596-525e0706864c',
    label: 'show_wazt_search_results',
    requiredFlags: [['WAZT_PAYMENT_SEARCH']],
    hideFlags: [['WAZT_PAYMENT_SEARCH_COMPLETE']],
    options: [
      {
        label: 'Go back to the email',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['WAZT_PAYMENT_SEARCH_COMPLETE'],
        hideFlags: [],
      },
    ],
  },
  {
    id: '9ff64b8c-3ebf-4655-950e-7d0883352fd5',
    label: 'show_wazt_payment_screen',
    requiredFlags: [['WAZT_PAYMENT_MAKE']],
    hideFlags: [['WAZT_PAYMENT_SENT']],
    options: [],
  },
  {
    id: '07e4d19e-e73f-4cc6-b020-1076cf030d40',
    label: 'show_go_to_lunch',
    requiredFlags: [['RESULT_FAKE_REQUEST']],
    hideFlags: [['BACK_FROM_LUNCH_DAY_1']],
    options: [
      {
        label: 'Go to lunch',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['BACK_FROM_LUNCH_DAY_1'],
      },
    ]
  },
  // {
  //   id: '2d510855-3c5d-471a-8625-b7237e250ed8',
  //   label: 'show_back_from_lunch',
  //   requiredFlags: [['GOTO_LUNCH_DAY_1']],
  //   hideFlags: [['BACK_FROM_LUNCH_DAY_1']],
  //   options: [
  //     {
  //       label: 'Get back to work',
  //       requiredCounters: [],
  //       setCounters: [],
  //       requiredFlags: [],
  //       hideFlags: [],
  //       setFlags: ['BACK_FROM_LUNCH_DAY_1'],
  //     },
  //   ]
  // },
  {
    id: '0c7c25d0-d0b8-4efc-95b9-e330b2c1e7e6',
    label: 'create_clambones_order',
    requiredFlags: [['WIDGET_CALL_COMPLETE', 'WIDGET_CALL_AGREE']],
    hideFlags: [['WIDGET_CALL_ORDER_CREATED']],
  },
  {
    id: '2d510855-3c5d-471a-8625-b7237e250ed8',
    label: 'day_1_ends',
    requiredFlags: [['RESULT_FAKE_ORDER']],
    hideFlags: [['DAY_2_STARTS']],
    options: [
      {
        label: 'Call it a day',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['DAY_2_STARTS'],
      },
    ]
  },
  // {
  //   id: '2d510855-3c5d-471a-8625-b7237e250ed8',
  //   label: 'day_2_starts',
  //   requiredFlags: [['DAY_1_ENDS']],
  //   hideFlags: [['DAY_2_STARTS']],
  //   options: [
  //     {
  //       label: 'Start a new day',
  //       requiredCounters: [],
  //       setCounters: [],
  //       requiredFlags: [],
  //       hideFlags: [],
  //       setFlags: ['DAY_2_STARTS'],
  //     },
  //   ]
  // },
  {
    id: '4522cc33-a864-4b8b-9700-b58960b727da',
    label: 'voucher_purchase',
    requiredFlags: [['VOUCHER_AGREE']],
    hideFlags: [['VOUCHER_PURCHASE_COMPLETE']],
    options: [
      {
        label: 'Send purchased voucher codes',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['VOUCHER_PURCHASE_COMPLETE'],
      }
    ],
  },
  {
    id: '474e3634-9e0b-42e3-8910-c75fb6660eb8',
    label: 'open_finance_software',
    requiredFlags: [['INVOICE_OPENED']],
    hideFlags: [['INVOICE_ACCEPTED'], ['INVOICE_REJECTED'], ['INVOICE_COMPLETE']],
    options: [
      {
        label: 'Pay the invoice',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['INVOICE_ACCEPTED'],
      },
      {
        label: 'Reject the invoice',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['INVOICE_REJECTED'],
      },
      {
        label: 'Ignore the invoice',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['INVOICE_REJECTED'],
      },
    ]
  },
  {
    id: 'de70ff2d-1c09-4ff9-b99e-c86af8cbbe3d',
    label: 'it_takeover',
    requiredFlags: [['IT_ACCESS_GRANTED']],
    hideFlags: [['IT_TAKEOVER']],
    options: [],
  },
  {
    id: 'c55581f4-5ff4-4813-a2cf-565d6252e479',
    label: 'day_2_ends',
    requiredFlags: [['RESULT_IT']],
    hideFlags: [['DAY_3_STARTS']],
    options: [
      {
        label: 'Time to go home',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['DAY_3_STARTS'],
      },
    ]
  },
  // {
  //   id: '2d510855-3c5d-471a-8625-b7237e250ed8',
  //   label: 'day_3_starts',
  //   requiredFlags: [['DAY_2_ENDS']],
  //   hideFlags: [['DAY_3_STARTS']],
  //   options: [
  //     {
  //       label: 'A new day, let\'s get started',
  //       requiredCounters: [],
  //       setCounters: [],
  //       requiredFlags: [],
  //       hideFlags: [],
  //       setFlags: ['DAY_3_STARTS'],
  //     },
  //   ]
  // },
  {
    id: 'c184cd0c-81be-48fc-aa9f-034ba161a3cb',
    label: 'account_change',
    requiredFlags: [['ACCOUNT_CHANGE_ACCEPT']],
    hideFlags: [['ACCOUNT_CHANGE_COMPLETE']],
    options: [
      {
        label: 'Change details',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['ACCOUNT_CHANGE_COMPLETE'],
      }
    ]
  },
  {
    id: '8093a669-0d42-4769-8387-45cd20d2616a',
    label: 'procure_new',
    requiredFlags: [['PROCURE_NEW_SUPPLIER']],
    hideFlags: [['PROCURE_COMPLETE']],
    options: [
      {
        label: 'Complete order',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['PROCURE_COMPLETE'],
      }
    ],
  },
  {
    id: '7eb4c4b5-73fd-469f-bdaf-b1689f1bc311',
    label: 'procure_old',
    requiredFlags: [['PROCURE_OLD_SUPPLIER']],
    hideFlags: [['PROCURE_COMPLETE']],
    options: [
      {
        label: 'Complete order',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['PROCURE_COMPLETE'],
      }
    ],
  },
  {
    id: '2db9f04d-c0cc-40c7-825b-a49d6d11061e',
    label: 'summary',
    requiredFlags: [['RESULT_PROCUREMENT']],
    hideFlags: [['CHALLENGE_FINISHED']],
    options: [],
  },
];

export default events;