import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import GameContainer from './components/GameContainer';
import {store, persistor} from './redux/store';


function TakeFiveApp() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor} >
        <GameContainer />
      </PersistGate>
    </Provider>
  );
}

export default TakeFiveApp;
