import {createSlice} from '@reduxjs/toolkit';
import {appSelectors} from './appSlice';
import narrative from '../../data/narrative';

const analyticsURL = process.env.NODE_ENV === 'production' ? 'https://t5a.fourcommunications.digital/analytics.php' : 'http://take5-analytics.test/analytics.php';
const queryParams = new URLSearchParams(window.location.search);
const referrer = queryParams.has('referrer') ? queryParams.get('referrer') : null;

export const flagGroupStatus = {
  FLAGS_ALL_UNSET: 0,
  FLAGS_ALL_SET: 1,
  FLAGS_MIXED: 2,
};

const initialState = {
  flags: [],
  counters: [],
  ...narrative,
};

const narrativeSlice = createSlice({
  name: 'narrative',
  initialState,
  reducers: {
    resetAllFlags: (state, action) => {
      state.flags = initialState.flags;
      state.counters = initialState.counters;
      state.narrativeData = initialState.narrativeData;
    },
    updateFlags: (state, action) => {
      const currentFlags = [...state.flags];
      const newFlags = currentFlags.concat(action.payload);
      state.flags = [...new Set(newFlags)];   // This is getting rid of duplicate entries
    },
    updateCounter: (state, action) => {
      const currentCounters = [...state.counters];
      const newCounters = currentCounters.concat(action.payload);
      state.counters = [...new Set(newCounters)]; // This is getting rid of duplicate entries
    },
    removeFlags: (state, action) => {
      const currentFlags = [...state.flags];
      const newFlags = currentFlags.filter(flag => !action.payload.includes(flag));
      state.flags = newFlags;
    },
  },
});

const contextualFlagSetter = (id, type, state, dispatch) => {
  const selectedItemIndex = state.narrativeData.findIndex(narrativeItem => narrativeItem.narrativeType === type && narrativeItem.id === id);
  if (selectedItemIndex !== -1) {
    dispatch(setFlags([`${type.toUpperCase()}_TRIGGERED_${id}`]))
  }
};

export const setFlags = (payload) => (dispatch, getState) => {
  dispatch(narrativeSlice.actions.updateFlags(payload));
  const state = getState();
  const sessionId = appSelectors.getSessionId(state);
  const currentFlags = [...state.narrative.flags];
  const newFlags = currentFlags.concat(payload);
  const uniqueFlags = [...new Set(newFlags)];   // This is getting rid of duplicate entries
  
  if(uniqueFlags.length > 1) {
    fetch(analyticsURL, {
      method: 'POST',
      cache: 'no-cache',
      body: JSON.stringify({
        sessionId,
        flags: uniqueFlags,
        referrer
      })
    });
  }
};

export const setCounter = (payload) => (dispatch, getState) => {
  dispatch(narrativeSlice.actions.updateCounter(payload));
};

export const setFlagsAndCounters = (payload) => (dispatch, getState) => {
  dispatch(setFlags(payload.setFlags));
  dispatch(setCounter(payload.setCounters));
};

export const setFocusTriggered = (payload) => (dispatch, getState) => {
  contextualFlagSetter(payload.focusId, 'focus', getState().narrative, dispatch);
};

export const setOverlayTriggered = (payload) => (dispatch, getState) => {
  contextualFlagSetter(payload.overlayId, 'overlay', getState().narrative, dispatch);
};

export const markEmailRead = (payload) => (dispatch, getState) => {
  contextualFlagSetter(payload.emailId, 'email', getState().narrative, dispatch);
};

export const markMessageSentReceived = (payload) => (dispatch, getState) => {
  contextualFlagSetter(payload.messageId, 'message', getState().narrative, dispatch);
};

export const setAdviceTriggered = (payload) => (dispatch, getState) => {
  contextualFlagSetter(payload.adviceId, 'advice', getState().narrative, dispatch);
}

export const markMessagesReadByContact = (payload) => (dispatch,getState) => {
  const narrativeData = [...getState().narrative.narrativeData];
  const {contact} = payload;
  const setFlags = narrativeSelectors.getSetFlags(getState().narrative);
  const messages = [...narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'message' && narrativeItem.contact === contact && checkFlagGroupStatus(setFlags, narrativeItem.requiredFlags) === flagGroupStatus.FLAGS_ALL_SET)];
  for (let i=0; i < messages.length; i++) {
    contextualFlagSetter(messages[i].id, 'message', getState().narrative, dispatch);
  }
};

export const narrativeSelectors = {
  getSetFlags: state => state.narrative.flags,
  checkFlagSet: (state, flag) => {
    const setFlags = narrativeSelectors.getSetFlags(state);
    return setFlags.includes(flag);
  },
  getActiveOptions: (state, options) => {
    if(!options || options.length === 0) return [];
    const setFlags = narrativeSelectors.getSetFlags(state);
    return options.filter(option => {
      const hideFlags = option.hideFlags || [];
      const requiredFlagsStatus = checkFlagGroupStatus(setFlags, option.requiredFlags); // Are all the required flags set?
      const hideFlagsStatus = hideFlags.length > 0 ? checkFlagGroupStatus(setFlags, hideFlags) : flagGroupStatus.FLAGS_ALL_UNSET;  // Are all of the "hide this option" flags set?  For an empty array we assume flags unset (we don't hide)
      return !(requiredFlagsStatus !== flagGroupStatus.FLAGS_ALL_SET || hideFlagsStatus === flagGroupStatus.FLAGS_ALL_SET); // Required options are set, "hide this option" flags are not all set
    });
  },
  getActiveOptionsCount: (state, options) => {
    return narrativeSelectors.getActiveOptions(state, options).length;
  },
  getCurrentEmails: state => {
    const setFlags = narrativeSelectors.getSetFlags(state);
    return state.narrative.narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'email').filter(email => {
      return checkFlagGroupStatus(setFlags, email.requiredFlags) === flagGroupStatus.FLAGS_ALL_SET;
    }).reverse().map(email => {
      return {
        ...email, 
        activeOptionCount:  narrativeSelectors.getActiveOptionsCount(state, email.options),
        read: !!(narrativeSelectors.checkFlagSet(state, `EMAIL_TRIGGERED_${email.id}`))
      };
    });
  },
  getCurrentEvents: state => {
    const setFlags = narrativeSelectors.getSetFlags(state);
    return state.narrative.narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'event').filter(event => {
      return checkFlagGroupStatus(setFlags, event.requiredFlags) === flagGroupStatus.FLAGS_ALL_SET 
          && checkFlagGroupStatus(setFlags, event.hideFlags) === flagGroupStatus.FLAGS_ALL_UNSET;
    });
  },
  getCurrentCalls: state => {
    const setFlags = narrativeSelectors.getSetFlags(state);
    return state.narrative.narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'call').filter(call => {
      return checkFlagGroupStatus(setFlags, call.requiredFlags) === flagGroupStatus.FLAGS_ALL_SET
          && checkFlagGroupStatus(setFlags, call.hideFlags) === flagGroupStatus.FLAGS_ALL_UNSET;
    });
  },
  getCurrentMessages: state => {
    const setFlags = narrativeSelectors.getSetFlags(state);
    return state.narrative.narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'message').filter(message => {
      return checkFlagGroupStatus(setFlags, message.requiredFlags) === flagGroupStatus.FLAGS_ALL_SET;    
    }).map(message => {
      return {...message,  sentOrReceived: !!(narrativeSelectors.checkFlagSet(state, `MESSAGE_TRIGGERED_${message.id}`))};
    });
  },
  getUnreadEmailCount: state => {
    const currentEmails = narrativeSelectors.getCurrentEmails(state);
    const unreadEmails = currentEmails.filter(email => !email.read);
    return unreadEmails.length;
  },
  getUnreadMessageCount: state => {
    const currentMessages = narrativeSelectors.getCurrentMessages(state);
    const unreadMessages = currentMessages.filter(message => !message.sentOrReceived && message.direction === "incoming");
    const unreadMessagesByContact = unreadMessages.reduce((result, currentMessage) => {
      if (result[currentMessage.contact] === undefined) {
        result[currentMessage.contact] = 1;
      } else {
        result[currentMessage.contact]++;
      }
      return result;
    }, {})
    return {total: unreadMessages.length, byContact: unreadMessagesByContact};
  },
  getCurrentCallActive: state => {
    const calls = narrativeSelectors.getCurrentCalls(state);
    return calls && calls.length !== 0;
  },
  getCurrentCallRinging: state => {
    const calls = narrativeSelectors.getCurrentCalls(state);
    return calls && calls.length !== 0 && calls[0].ringing;
  },
  getCurrentCallVoicemail: state => {
    const calls = narrativeSelectors.getCurrentCalls(state);
    return calls && calls.length !== 0 && calls[0].calling === 'Voicemail';
  },
  getCurrentTeabreak: state => {
    const setFlags = narrativeSelectors.getSetFlags(state);
    return state.narrative.narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'teabreak').filter(teabreak => {
      return checkFlagGroupStatus(setFlags, teabreak.requiredFlags) === flagGroupStatus.FLAGS_ALL_SET
          && checkFlagGroupStatus(setFlags, teabreak.hideFlags) === flagGroupStatus.FLAGS_ALL_UNSET;
    });    
  },
  getCurrentFocus: state => {
    const setFlags = narrativeSelectors.getSetFlags(state);
    return state.narrative.narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'focus').filter(focus => {
      return checkFlagGroupStatus(setFlags, [[`FOCUS_TRIGGERED_${focus.id}`]]) === flagGroupStatus.FLAGS_ALL_UNSET 
          && checkFlagGroupStatus(setFlags, focus.requiredFlags) === flagGroupStatus.FLAGS_ALL_SET;
    }).map((focus) => {
      return {
        ...focus,
        triggered: !!(narrativeSelectors.checkFlagSet(state, `FOCUS_TRIGGERED_${focus.id}`))
    }});
  },
  getCurrentOverlay: state => {
    const setFlags = narrativeSelectors.getSetFlags(state);
    return state.narrative.narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'overlay').filter(overlay => {
      return checkFlagGroupStatus(setFlags, [[`OVERLAY_TRIGGERED_${overlay.id}`]]) === flagGroupStatus.FLAGS_ALL_UNSET 
          && checkFlagGroupStatus(setFlags, overlay.requiredFlags) === flagGroupStatus.FLAGS_ALL_SET;
    });
  },
  getCurrentResult: state => {
    const setFlags = narrativeSelectors.getSetFlags(state);
    return state.narrative.narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'result').filter(result => {
      return checkFlagGroupStatus(setFlags, result.requiredFlags) === flagGroupStatus.FLAGS_ALL_SET
        && checkFlagGroupStatus(setFlags, result.hideFlags) === flagGroupStatus.FLAGS_ALL_UNSET;
    })
  },
  getCurrentBackground: (state, target) => {
    const setFlags = narrativeSelectors.getSetFlags(state);
    return state.narrative.narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'background').filter(background => {
      return checkFlagGroupStatus(setFlags, background.requiredFlags) === flagGroupStatus.FLAGS_ALL_SET
          && checkFlagGroupStatus(setFlags, background.hideFlags) === flagGroupStatus.FLAGS_ALL_UNSET
          && background.target === target;
    });
  },
  getAdviceExistsByLabel: (state, adviceLabel) => {
    return state.narrative.narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'advice').findIndex(adviceItem => adviceItem.label === adviceLabel) !== -1;
  },
  getAdviceByLabel: (state, adviceLabel) => {
    return state.narrative.narrativeData.filter(narrativeItem => narrativeItem.narrativeType === 'advice').find(adviceItem => adviceItem.label === adviceLabel);
  }
};

// For any given array of flags (flagGroup - which could be a requiredFlags or hideFlags etc) we need to determine if one of the 
// following two states exist:
//
//    - At least one of the nested AND groups is true -> This is the same as saying the whole compound flag is true. (FLAGS_ALL_SET)
//    - None of the nested AND groups are true -> This is the same as saying the whole compoung flag is false (FLAGS_ALL_UNSET)
//
//  This code is mashed together from before the nested ANDs so the variable names are a bit off.  Also the the third condition is
//  no longer relevant and I think that code is unreachable.
//
export const checkFlagGroupStatus = (setFlags, flagGroup) => {    
  if(flagGroup.length === 0) {                                    
    return flagGroupStatus.FLAGS_ALL_SET;
  }

  const allTrue = flagGroup.reduce((orResult, currentFlagOrGroup) => {
    const andGroupResult = currentFlagOrGroup.reduce((andResult, currentFlag) => setFlags.includes(currentFlag) && andResult, true);  // AND together the member of subgroups, then OR those results
    return andGroupResult || orResult;
  }, false);

  if (allTrue) {
    return flagGroupStatus.FLAGS_ALL_SET;
  }

  const allFalse = flagGroup.reduce((orResult, currentFlagOrGroup) => {
    const andGroupResult = !currentFlagOrGroup.reduce((andResult, currentFlag) => setFlags.includes(currentFlag) && andResult, true); // AND together the member of subgroups, then AND the INVERSE of those results
    return andGroupResult && orResult;
  }, true);

  if (allFalse) {
    return flagGroupStatus.FLAGS_ALL_UNSET;
  }

  //UNREACHABLE CODE: REMOVE ONCE TESTED
  return flagGroupStatus.FLAGS_MIXED;
}

//setFlags, setCounter, setFlagsAndCounters
export const {resetAllFlags, removeFlags} = narrativeSlice.actions;
export default narrativeSlice.reducer;