import {useState, useCallback, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {narrativeSelectors, setFocusTriggered, setFlags} from '../../redux/slices/narrativeSlice';
import {appSelectors, setFocus} from '../../redux/slices/appSlice';

import MuteButton from '../Elements/MuteButton';
import OnePagerLink from '../Elements/OnePagerLink';
import FocusSwitcher from '../Elements/FocusSwitcher';
import Overlay from '../Overlay';
import Result from '../Result';
import Flags from '../Flags';
import AudioEffect from '../Elements/AudioEffects';
import Desk from '../Desk';
import Email from '../Email';
import Call from '../Call';
import Message from '../Message';
import Event from '../Event';
import OnePager from '../Events/OnePager';
import TeaBreak from '../TeaBreak';
import Advice from '../Advice';

import './styles.scss';

const GameContainer = props => {
  const dispatch = useDispatch();
  const activeFocus = useSelector(narrativeSelectors.getCurrentFocus);
  const activeOverlay = useSelector(narrativeSelectors.getCurrentOverlay);
  const activeResult = useSelector(narrativeSelectors.getCurrentResult);
  const activeAdvice = useSelector(appSelectors.getCurrentAdvice);
  const sessionId = useSelector(appSelectors.getSessionId);
  const focus = useSelector(appSelectors.getFocus);
  const showOnePagerLink = useSelector(appSelectors.getOnePagerLinkVisible);
  const [showDebug, setShowDebug] = useState(false);

  useEffect(() => {
    if(activeFocus.length > 0) {
      const currentFocus = activeFocus[0];
      if(!currentFocus.triggered) {
        dispatch(setFocus({target: currentFocus.target, params: currentFocus.params}));
        dispatch(setFocusTriggered({focusId: currentFocus.id}));
      }
    }
  }, [activeFocus, dispatch]);

  useEffect(() => {
    if (sessionId) {
      dispatch(setFlags(['PAGE_LOADED']));
    }
  }, [dispatch, sessionId]);


  const handleFocusSwitch = useCallback(screen => {
    dispatch(setFocus({target: screen, params: {}}));
  }, [dispatch]);

  const screenMap = {
    desk: Desk,
    email: Email,
    call: Call,
    message: Message,
    event: Event,
    onepager: OnePager,
    teabreak: TeaBreak

  }

  const SelectedElement = (focus && focus.target && Object.keys(screenMap).includes(focus.target) )? screenMap[focus.target] : screenMap.desk;

  const handleWindowKeyPress = useCallback((event) => {
    if (event.which === 100) {
      // setShowDebug(previous => !previous);
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keypress', handleWindowKeyPress);
    return () => window.removeEventListener('keypress', handleWindowKeyPress);
  }, [handleWindowKeyPress]);

  return (
    <>
      <AudioEffect />
      <div className="container">
        <div className="portrait-warning">
          <h2>Please turn your device to landscape mode</h2>
          <img alt="rotate your device" src={require('../../assets/img/anim-rotate-phone.gif')} />
        </div>

        <div className="container-inner">
          <MuteButton />
          <SelectedElement target={focus.target} params={focus.params} />
          {showOnePagerLink ? <OnePagerLink /> : null}
          {activeOverlay && activeOverlay.length && !activeOverlay[0].triggered ? <Overlay {...activeOverlay[0]} /> : null}
          {activeResult && activeResult.length ? <Result {...activeResult[0]} /> : null}
          {activeAdvice && <Advice advice={activeAdvice} />}
        </div>
      </div>
      {showDebug && (
        <>
          <Flags />
          <FocusSwitcher callBack={handleFocusSwitch} screenMap={screenMap} focus={focus} />
        </>
      )}

    </>
  );
};

export default GameContainer;