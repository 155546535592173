const results = [
  {
    id: "9f32973d-448c-4f18-8b45-a433546ac40f",
    label: 'money_transfer_scam_fail',
    text: '<h2>Unfortunately, you just fell for a scam</h2><p>While Roberta is really the CEO of the company, this could also be a fake request from someone with knowledge of your organisation. </p><p>Criminals try to make requests look urgent and time-sensitive to pressure you into making bad decisions. We know that you may not have time to verify every single request, but if there is something unusual about a request, if it comes via a channel you don’t expect (particularly to your personal email or mobile), or if it involves a change to what you’re used to, it is higher risk and you should take some time to check it out.</p><p>On this occasion it is a new supplier who you do not recognise and it is for a substantial amount of money. While you should check the email address carefully, criminals can spoof email addresses or gain illicit access to email accounts, so you can’t and shouldn’t rely on that alone.</p><p>Checking in-person or via a different communications channel with the person making the request is a good way to avoid falling for a fraud or scam.</p>',
    requiredFlags: [['WAZT_PAYMENT_SENT']],
    hideFlags: [['RESULT_FAKE_REQUEST']],
    closeFlag: 'RESULT_FAKE_REQUEST',
  },
  {
    id: "9c10de66-99aa-46da-af5b-58ebf6cffab2",
    label: 'money_transfer_scam_succeed',
    text: '<h2>Congratulations, you avoided that scam</h2><p>While Roberta is really the CEO of the company, this could also be a fake request from someone with knowledge of your organisation. </p><p>Criminals try to make requests look urgent and time-sensitive to pressure you into making bad decisions. We know that you may not have time to verify every single request, but if there is something unusual about a request, if it comes via a channel you don’t expect (particularly to your personal email or mobile), or if it involves a change to what you’re used to, it is higher risk and you should take some time to check it out.</p><p>On this occasion it is a new supplier who you do not recognise and it is for a substantial amount of money. While you should check the email address carefully, criminals can spoof email addresses or gain illicit access to email accounts, so you can’t and shouldn’t rely on that alone.</p><p>Checking in-person or via a different communications channel with the person making the request is a good way to avoid falling for a fraud or scam.</p>',
    requiredFlags: [['WAZT_PAYMENT_NOTHING']],
    hideFlags: [['RESULT_FAKE_REQUEST']],
    closeFlag: 'RESULT_FAKE_REQUEST',
  },
  {
    id: "c49c979b-7a7c-445b-ae28-e779318a9d5e",
    label: 'fake_order_scam_fail',
    text: '<h2>Unfortunately, you just fell for a scam</h2><p>Criminals can easily pretend to be existing customers and place fraudulent orders.</p><p>Look out for any order that looks different from normal. It might be higher risk and you should take the time to verify it with the customer via another channel, or match the details with your systems to make sure it is legitimate. </p><p>Focus and concentrate on orders that are out of the ordinary and which are therefore higher risk. These might be orders which are significantly different in scale, contain high value goods, that ask for changes to be made, in some cases more than once, or are from new customers that may be overseas making it harder to check address details. </p><p>In this scenario there are three potential warning signs:</p><ul><li>The company in this attempted scam normally orders 40 widgets at a time, rather than 5,000</li><li>They are asking for delivery to a new address</li><li>They are not the person you normally speak to.</li></ul>',
    requiredFlags: [['WIDGET_CALL_ORDER_CREATED']],
    hideFlags: [['RESULT_FAKE_ORDER']],
    closeFlag: 'RESULT_FAKE_ORDER',
  },
  {
    id: "c49c979b-7a7c-445b-ae28-e779318a9d5e",
    label: 'fake_order_scam_succeed',
    text: '<h2>Congratulations, you avoided that scam</h2><p>Criminals can easily pretend to be existing customers and place fraudulent orders.</p><p>Look out for any order that looks different from normal. It might be higher risk and you should take the time to verify it with the customer via another channel, or match details with your systems to make sure it is legitimate. </p><p>Focus and concentrate on orders that are out of the ordinary and which are therefore higher risk. These might be orders which are significantly different in scale, contain high value goods, that ask for changes to be made, in some cases more than once, or are from new customers that may be in some cases be overseas making it harder to check address details. </p><p>In this scenario there are three potential warning signs:</p><ul><li>The company in this attempted scam normally orders 40 widgets at a time, rather than 5,000</li><li>They are asking for delivery to a new address</li><li>They are not the person you normally speak to.</li></ul>',
    requiredFlags: [['WIDGET_CALL_REFUSE', 'WIDGET_CALL_COMPLETE']],
    hideFlags: [['RESULT_FAKE_ORDER']],
    closeFlag: 'RESULT_FAKE_ORDER',
  },  
  {
    id: "4d8be1db-7f7e-49d2-b98d-26e819a6829d",
    label: 'impersonation_fail',
    text: '<h2>Unfortunately, you just fell for a CEO impersonation scam</h2><p>We know that Roberta is really the boss, and again we are left with no way to contact her to confirm this request.  </p><p>But there are some red flags that suggest this is a high-risk request: </p><ul><li>She is asking for you to break standard procedure or rules: giving her your individual login details, without going to IT first.</li><li>She is contacting you via a personal device rather than company email or in-person.</li><li>She is hurrying you or making you feel uncomfortable in checking her details.</li></ul><p>These kinds of scams can be really hard to spot because not only can criminals impersonate emails and texts from individuals, but can even mimic the sound of someone’s voice or their face using ‘deep fake’ AI technologies – particularly if they have access to recordings of the individual from webinars or podcasts. </p>',
    requiredFlags: [['VOICEMAIL_LOGIN_AGREE_SENT']],
    hideFlags: [['RESULT_IMPERSONATION']],
    closeFlag: 'RESULT_IMPERSONATION'
  },
  {
    id: "4d8be1db-7f7e-49d2-b98d-26e819a6829d",
    label: 'impersonation_succeed',
    text: '<h2>Congratulations, you avoided the CEO impersonation scam</h2><p>We know that Roberta is really the boss, and again we are left with no way to contact her to confirm this request.  </p><p>But there are some red flags that suggest this is a high-risk request: </p><ul><li>She is asking for you to break standard procedure or rules: giving her your individual login details, without going to IT first.</li><li>She is contacting you via a personal device rather than company email or in-person.</li><li>She is hurrying you or making you feel uncomfortable in checking her details.</li></ul><p>These kinds of scams can be really hard to spot because not only can criminals impersonate emails and texts from individuals, but can even mimic the sound of someone’s voice or their face using ‘deep fake’ AI technologies – particularly if they have access to recordings of the individual from webinars or podcasts. </p>',
    requiredFlags: [['VOICEMAIL_LOGIN_REFUSE_IGNORE']],
    hideFlags: [['RESULT_IMPERSONATION']],
    closeFlag: 'RESULT_IMPERSONATION'
  },
  {
    id: "ac0c0710-895a-469d-8482-faef8342344e",
    label: 'voucher_fail',
    text: '<h2>Unfortunately, you fell for the gift card scam</h2><p>In this scam, someone (sometimes even an insider within your organisation) asks you to purchase and expense something that can be easily traded elsewhere, like online voucher codes.</p><p>The people these codes are emailed to then sell them on to third parties, often at a fraction of the original cost.</p><p>The scam relies on expenses not being carefully checked, and employees trusting each other without checking. This happens particularly for expenses outside of the normal operation of businesses – particularly one-off purchases for internal events and activities.</p>',
    requiredFlags: [['VOUCHER_PURCHASE_COMPLETE']],
    hideFlags: [['RESULT_VOUCHER']],
    closeFlag: 'RESULT_VOUCHER',
  },
  {
    id: "fb489bf6-fd52-40ea-b5d1-f30d13af3343",
    label: 'voucher_succeed',
    text: '<h2>Congratulations – you didn’t fall for the gift card scam</h2><p>In this scam, someone (sometimes even an insider within your organisation) asks you to purchase and expense something that can be easily traded elsewhere, like online voucher codes.</p><p>The people these codes are emailed to then sell them on to third parties, often at a fraction of the original cost.</p><p>The scam relies on expenses not being carefully checked, and employees trusting each other without checking. This happens particularly for expenses outside of the normal operation of businesses – particularly one-off purchases for internal events and activities.</p>',
    requiredFlags: [['VOUCHER_REFUSE_2']],
    hideFlags: [['RESULT_VOUCHER']],
    closeFlag: 'RESULT_VOUCHER',
  },
  {
    id: "01b69d76-1313-4c12-95c0-c53a69bd4197",
    label: 'invoice_result_fail',
    text: '<h2>Unfortunately this wasn’t a scam!</h2><p>OK, so we put this one in to catch you out.</p><p>We know that small businesses find it harder to verify every order, invoice and request that comes in.  If by your best judgement they are low risk, and the sums are not substantial, then you may be less thorough in checking.</p><p>Some indications that this is a low risk request when taken together include:</p><ul><li>A history of previous payments to the same account details</li><li>No request to change any information or payment details/account details</li><li>The invoice is in line with previous invoices </li></ul>',
    requiredFlags: [['INVOICE_REJECTED']],
    hideFlags: [['RESULT_INVOICE']],
    closeFlag: 'RESULT_INVOICE',
  },
  {
    id: "62d29c51-385c-4eb1-a4f3-dcf711b45315",
    label: 'invoice_result_success',
    text: '<h2>Congratulations, this wasn’t a scam!</h2><p>OK, so we put this one in to catch you out.  Well done for spotting it.</p><p>We know that small businesses find it harder to verify every order, invoice and request that comes in.  If by your best judgement they are low risk, and the sums are not substantial, then you may be less thorough in checking.</p><p>Some indications that this is a low risk request when taken together include:</p><ul><li>A history of previous payments to the same account details</li><li>No request to change any information or payment details/account details</li><li>The invoice is in line with previous invoices </li></ul>',
    
    requiredFlags: [['INVOICE_ACCEPTED']],
    hideFlags: [['RESULT_INVOICE']],
    closeFlag: 'RESULT_INVOICE',
  },
  {
    id: "e3997af3-e882-4926-bf8f-adec33ac76cf",
    label: 'it_fail',
    text: '<h2>Unfortunately, you just fell for a helpdesk scam</h2><p>Criminals often pose as members of an IT team (or a third-party consultant) to get access to your computer. Once they have access, they can do everything that you would be able to do, so it’s really important only to give access to people you know and trust.</p><p>Criminals use many tactics to trick you, including spoofing which makes their call, message or email appear genuine.  These messages will often lead to a website that is made to look legitimate using another tactic called cloning.</p><p>Cloned websites look almost identical to the real website of a trusted organisation.</p><p>In this scenario: </p><ul><li>The caller doesn’t identify themselves at all, and you have no reason to believe they are who they say they are.</li><li>They are also calling proactively to address a problem you haven’t reported.</li><li>They are asking you to download a new piece of software on your device.</li></ul>',
    requiredFlags: [['IT_TAKEOVER']],
    hideFlags: [['RESULT_IT']],
    closeFlag: 'RESULT_IT'
  },
  {
    id: "e3997af3-e882-4926-bf8f-adec33ac76cf",
    label: 'it_succeed',
    text: '<h2>Congratulations, you avoided that helpdesk scam</h2><p>Criminals often pose as members of an IT team (or a third-party consultant) to get access to your computer. Once they have access, they can do everything that you would be able to do, so it’s really important only to give access to people you know and trust.</p><p>Criminals use many tactics to trick you, including spoofing which makes their call, message or email appear genuine.  These messages will often lead to a website that is made to look legitimate using another tactic called cloning.</p><p>Cloned websites look almost identical to the real website of a trusted organisation.</p><p>In this scenario: </p><ul><li>The caller doesn’t identify themselves at all, and you have no reason to believe they are who they say they are.</li><li>They are also calling proactively to address a problem you haven’t reported.</li><li>They are asking you to download a new piece of software on your device.</li></ul>',
    requiredFlags: [['IT_ACCESS_IGNORE'], ['IT_COMPLETE']],
    hideFlags: [['RESULT_IT']],
    closeFlag: 'RESULT_IT'
  },
  {
    id: '0b77a264-f9f6-4c2c-9810-2b98e5327c03',
    label: 'details_fail',
    text: '<h2>Unfortunately, you just fell for an invoice scam</h2><p>You should always take care when a supplier asks you to change payment details because this is one of the most common scams, affecting businesses of all sizes.</p><p>If scammers can convince you to redirect payments for goods or services, you may end up paying twice once the original supplier gets in touch to chase payment.</p><p>Of course, suppliers might have valid reasons to change bank accounts, or may be required to redirect payment to a company providing them with invoice credit. But you should always check carefully by verifying the details directly with a known contact either in person or on the phone before proceeding.</p>',
    requiredFlags: [ ['ACCOUNT_CHANGE_COMPLETE']],
    hideFlags: [['RESULT_DETAILS']],
    closeFlag: 'RESULT_DETAILS',
  },
  {
    id: '1cb86f2f-1d59-4b83-9ee1-d1a7fd31ac03',
    label: 'details_succeed',
    text: '<h2>Congratulations, you avoided that invoice scam</h2><p>You should always take care when a supplier asks you to change payment details because this is one of the most common scams, affecting businesses of all sizes.</p><p>If scammers can convince you to redirect payments for goods or services, you may end up paying twice once the original supplier gets in touch to chase payment.</p><p>Of course, suppliers might have valid reasons to change bank accounts, or may be required to redirect payment to a company providing them with invoice credit. But you should always check carefully by verifying the details directly with a known contact either in person or on the phone before proceeding.</p>',
    requiredFlags: [['ACCOUNT_CHANGE_IGNORE']],
    hideFlags: [['RESULT_DETAILS']],
    closeFlag: 'RESULT_DETAILS',
  },
  {
    id: '465769fc-6138-4b64-912a-016189124311',
    label: 'procure_fail',
    text: '<h2>Unfortunately, you fell for a procurement scam.</h2><p>In this scam, someone convinces you to use a new supplier instead of buying from a regular supplier or going through normal procurement procedures and due diligence. The supplier would normally provide the goods or services, but at a higher price or lower quality than normal, and share some of the profits with the person who suggested them.</p><p>This person is normally someone you trust – a fellow employee, for example. The request is normally made for a valid reason – perhaps because a regular supplier has let them down, or due to an urgent request for something that isn’t normally supplied.</p><p>It can be hard to spot this scam because it is invisible, and on the surface everything appears to be normal. But these scams can cost small businesses dearly.</p>',
    requiredFlags: [['PROCURE_COMPLETE', 'PROCURE_NEW_SUPPLIER']],
    hideFlags: [['RESULT_PROCUREMENT']],
    closeFlag: 'RESULT_PROCUREMENT',
  },
  {
    id: '889440cb-70e8-4df3-928f-45f02ff8bc2d',
    label: 'procure_succeed',
    text: '<h2>Congratulations – you didn’t fall for a procurement scam.</h2><p>In this scam, someone convinces you to use a new supplier instead of buying from a regular supplier or going through normal procurement procedures and due diligence. The supplier would normally provide the goods or services, but at a higher price or lower quality than normal, and share some of the profits with the person who suggested them.</p><p>This person is normally someone you trust – a fellow employee, for example. The request is normally made for a valid reason – perhaps because a regular supplier has let them down, or due to an urgent request for something that isn’t normally supplied.</p><p>It can be hard to spot this scam because it is invisible, and on the surface everything appears to be normal. But these scams can cost small businesses dearly.</p>',
    requiredFlags: [['PROCURE_COMPLETE', 'PROCURE_OLD_SUPPLIER']],
    hideFlags: [['RESULT_PROCUREMENT']],
    closeFlag: 'RESULT_PROCUREMENT',
  },
];

export default results;