const overlays = [
  {
    id: "7431f99f-8505-489f-b91d-f8e30bb4b899",
    label: "desk",
    requiredFlags: [['GAME_STARTED']],
    timeOut: 5,
  },
  {
    id: "6890443b-171d-4ef0-b376-9d1cb286c51f",
    label: "notifications",
    requiredFlags: [['OVERLAY_TRIGGERED_7431f99f-8505-489f-b91d-f8e30bb4b899']],
    timeOut: 5,
  },
  {
    id: "775a8496-87d1-42fe-9995-ba2284e04b11",
    label: "options",
    requiredFlags: [['OVERLAY_TRIGGERED_6890443b-171d-4ef0-b376-9d1cb286c51f']],
    timeOut: 5,
  },
  {
    id: "be210f46-a35f-4c22-88b1-cefaa0863ac4",
    label: "back",
    requiredFlags: [['OVERLAY_TRIGGERED_775a8496-87d1-42fe-9995-ba2284e04b11']],
    timeOut: 5,
  },
  {
    id: "9873d41f-6df6-4184-b85a-fc02c548f9a3",
    label: "audio",
    requiredFlags: [['OVERLAY_TRIGGERED_be210f46-a35f-4c22-88b1-cefaa0863ac4']],
    timeOut: 5,
  },
];

export default overlays;