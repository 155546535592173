import {useCallback, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {narrativeSelectors, setFlagsAndCounters, checkFlagGroupStatus, flagGroupStatus} from '../../../redux/slices/narrativeSlice';
import {showAdvice} from '../../../redux/slices/appSlice';

import './styles.scss';

const OptionsList = props => {
  const dispatch = useDispatch();
  const setFlags = useSelector(narrativeSelectors.getSetFlags);
  const [displayOptions, setDisplayOptions] = useState([]);

  const handleOptionClick = useCallback(({setFlags, setCounters}) => {
    dispatch(setFlagsAndCounters({setFlags, setCounters}));
    if(props.optionClickCallback) {
      props.optionClickCallback();
    }

  }, [dispatch, props]);

  const handleAdviceClick = useCallback((adviceLabel) => {
    dispatch(showAdvice(adviceLabel));
  }, [dispatch]);


  // Whenever the props or the flags in the global store change we update our list of available options
  useEffect(() => {
    const {options} = props;
    const filteredOptions = options && options.length > 0 ? options.filter(option => {
      const hideFlags = option.hideFlags || [];
      const requiredFlagsStatus = checkFlagGroupStatus(setFlags, option.requiredFlags); // Are all the required flags set?
      const hideFlagsStatus = hideFlags.length > 0 ? checkFlagGroupStatus(setFlags, hideFlags) : flagGroupStatus.FLAGS_ALL_UNSET;  // Are all of the "hide this option" flags set?  For an empty array we assume flags unset (we don't hide)
      return !(requiredFlagsStatus !== flagGroupStatus.FLAGS_ALL_SET || hideFlagsStatus === flagGroupStatus.FLAGS_ALL_SET); // Required options are set, "hide this option" flags are not all set
    }) : [];
    setDisplayOptions(filteredOptions);

  }, [props, setDisplayOptions, setFlags]);

  // If there are no options to display let's just not show anything
  if(displayOptions.length === 0) {
    return null;
  }

  // Render out a list of visible (see useeffect hook above) options with buttons that dispatch actions to set their flags and counters
  return <div className='options-wrapper'>
    {/* {displayOptions.length > 0 && (<h2>Make a selection:</h2>)} */}
    <ul className='options-list'>
      {displayOptions && displayOptions.map((option, index) => {
        return <li key={`option-${index}`}><button onClick={() => handleOptionClick({setFlags: option.setFlags, setCounters: option.setCounters})}>{option.label}</button></li>;
      })}
      {props.advice && (<li><button onClick={() => handleAdviceClick(props.advice.adviceLabel)}>Get Advice</button></li>)}
    </ul>
  </div>
};

export default OptionsList;