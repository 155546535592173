import Monitor from '../../Elements/DeviceWrappers/Monitor';

import './styles.scss'

const ProcureNew = props => {
  return (<Monitor hideBack={true}>
    <div className="browser-wrapper stationary-1">
      <div className="browser-url">
        <p>http://www.ez-stationary.net</p>
      </div>
      <div className="browser-inner">
        <img alt="EZ stationary" src={require('../../../assets/img/logo-ez-stationary.png')} />
        <p>Your current order:</p>
        <p>You don't have an account, we'll create one for you once you complete your order.</p>
        <table className="cart">
          <thead>
            <tr>
              <th>Item</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Notebooks</td>
              <td>20</td>
              <td>£10.00</td>
              <td>£200.00</td>
            </tr>
            <tr>
              <td colSpan="2"></td>
              <td>VAT</td>
              <td>£33.33</td>
            </tr>
            <tr>
              <td colSpan="2"></td>
              <td>Grand Total</td>
              <td>£200.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Monitor>);
};

export default ProcureNew;