import BackButton from '../../BackButton';
import './styles.scss';

const Monitor = props => {
  return (<div className="monitor-wrapper">
    {!props.hideBack ? <BackButton {...props.backButtonProps} /> : null}
    {props && props.children}
  </div>);
};

export default Monitor;