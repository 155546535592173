import {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Phone from '../../Elements/DeviceWrappers/Phone';
import {setFlags} from '../../../redux/slices/narrativeSlice';
import {setOutgoingRing} from '../../../redux/slices/appSlice';

import './styles.scss';

const RobertaCall = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setOutgoingRing(true));
    return () => dispatch(setOutgoingRing(false));
  }, [dispatch]);

  const handleHangUpClick = () => {
    dispatch(setFlags(['WAZT_PAYMENT_CALL_COMPLETE']));
  };

  return(<Phone hideBack={true}>
      <div className="call-inner call-ringing">
        <p className="call-name">Roberta F</p>
        <img className="call-avatar" alt="avatar" src={require('../../../assets/img/icon-avatar.png')} />
        <button className="call-hangup" onClick={handleHangUpClick} />
      </div>
    </Phone>
  );
};

export default RobertaCall;