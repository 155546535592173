import {useDispatch} from 'react-redux';
import {useCallback, useEffect, useRef, useState} from 'react';
import Typewriter from 'typewriter-effect';
import {setFlags} from '../../../redux/slices/narrativeSlice';
import Monitor from '../../Elements/DeviceWrappers/Monitor'

import './styles.scss';

const ITTakeover = props => {
  const dispatch = useDispatch();
  const [currentAction, setCurrentAction] = useState(0);
  const actionTimerRef = useRef(null);
  const handleActionTick = useCallback(() => {
    setCurrentAction(current => {
      if(current === 6) {
        dispatch(setFlags(['IT_TAKEOVER']));
        clearInterval(actionTimerRef.current);
      }
      return current + 1;
    });
  }, [dispatch]);

  useEffect(() => {
    if(actionTimerRef.current !== null) clearInterval(actionTimerRef.current);
    actionTimerRef.current = setInterval(handleActionTick, 1000);

    return () => actionTimerRef.current && clearInterval(actionTimerRef.current);
  }, [handleActionTick]);

  return (<Monitor hideBack={true}>
    <div className="monitor-inner">
      <div className="copying-window">
        <h2>Uploading files</h2>
        <img src={require('../../../assets/img/icon-copying.gif')} alt="" />
        <p>Uploading files to cloud storage...</p>
      </div>

      {currentAction > 0 ? (<div className="browser-wrapper banking-takeover">
        <p className="browser-url">https://online-banking.com/password-reset</p>
        <div className="browser-inner">
          <div className="banking-header">
            <img src={require('../../../assets/img/icon-bank.png')} alt="" />
            <p>Banking</p>
          </div>
          <div className="banking-body">
            <div className="banking-email">
              <span>New email address: </span>
              {currentAction === 1 ? (<Typewriter options={{
                strings: 'slick_27@hax.ru',
                autoStart: true,
                loop: false,
                delay: 35,
              }} />) : null }
              {currentAction > 1 ? <p>slick_27@hax.ru</p> : null}
            </div>
            <div className="banking-password">
              <span>New password: </span>
              {currentAction === 2 ? (<Typewriter options={{
                strings: '•••••••••••••••',
                autoStart: true,
                loop: false,
                delay: 35,
              }} />) : null }
              {currentAction > 2 ?  <p>•••••••••••••••</p> : null}
            </div>
          </div>
        </div>
      </div>) : null}
    </div>
  </Monitor>);
};

export default ITTakeover;