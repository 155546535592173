import {useState, useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {appSelectors} from '../../../redux/slices/appSlice';
import {narrativeSelectors} from '../../../redux/slices/narrativeSlice';

const AudioEffect = props => {
  const emailCount = useSelector(narrativeSelectors.getUnreadEmailCount);
  const lampOn = useSelector(appSelectors.getLampOn);
  const lightOn = useSelector(appSelectors.getLightOn);
  const coffee = useSelector(appSelectors.getCoffee);
  const outgoingRing = useSelector(appSelectors.getOutgoingRing);
  // const phoneVibrating = useSelector(appSelectors.getPhoneVibrating);
  const currentCalls = useSelector(narrativeSelectors.getCurrentCalls);
  const muted = useSelector(appSelectors.getMuted);
  const lampSwitchAudio = useRef();
  const lightSwitchAudio = useRef();
  const coffeeDrinkAudio = useRef();
  const emailAlertAudio = useRef();
  const outgoingRingAudio = useRef();
  const phoneVibratingAudio = useRef();
  const previousEmailCount = useRef(emailCount);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    setInitialLoad(false);
    return () => setInitialLoad(true);
  }, [initialLoad]);

  useEffect(() => {
    if(emailCount > previousEmailCount.current && !initialLoad) {
      setTimeout(() => emailAlertAudio.current.play(), 500);
    }
    previousEmailCount.current = emailCount;
  }, [emailCount, initialLoad]);

  useEffect(() => {
    if(lampSwitchAudio.current && !initialLoad) {
      lampSwitchAudio.current.play();
    }
  }, [initialLoad, lampOn, lampSwitchAudio]);

  useEffect(() => {
    if(lightSwitchAudio.current && !initialLoad) {
      if(lightOn) {
        lightSwitchAudio.current.play();
      } else {
        lampSwitchAudio.current.play();
      }
    }
  }, [lightOn, lampSwitchAudio, initialLoad]);

  useEffect(() => {
    if(coffee && coffeeDrinkAudio.current && !initialLoad) {
      coffeeDrinkAudio.current.play();
    }
  }, [coffee, coffeeDrinkAudio, initialLoad]);

  useEffect(() => {
    if(outgoingRing&&  outgoingRingAudio.current) {
      outgoingRingAudio.current.addEventListener('ended', () => repeatHandler(outgoingRingAudio.current), false);
      outgoingRingAudio.current.play();
    } else {
      outgoingRingAudio.current.removeEventListener('ended', () => repeatHandler(outgoingRingAudio.current), false);
      outgoingRingAudio.current.pause();
    }
  }, [outgoingRing, outgoingRingAudio]);
  
  useEffect(() => {

    if(currentCalls && currentCalls.length > 0 && currentCalls[0].ringing === true && phoneVibratingAudio.current) {
      phoneVibratingAudio.current.addEventListener('ended', () => repeatHandler(phoneVibratingAudio.current), false);
      phoneVibratingAudio.current.play();
    } else {
      phoneVibratingAudio.current.removeEventListener('ended', () => repeatHandler(phoneVibratingAudio.current), false);
      phoneVibratingAudio.current.pause();
    }
  }, [currentCalls, phoneVibratingAudio]);

  useEffect(() => {
    lampSwitchAudio.current.muted = muted;
    lightSwitchAudio.current.muted = muted;
    emailAlertAudio.current.muted = muted;
    coffeeDrinkAudio.current.muted = muted;
    outgoingRingAudio.current.muted = muted;
    phoneVibratingAudio.current.muted = muted;
  }, [muted]);

  const repeatHandler = (player) => {
    player.play();
  };

  return (
    <>
      <audio paused="paused" muted={muted} ref={lampSwitchAudio} src={require('../../../assets/audio/lamp-switch.mp3')} />
      <audio paused="paused" muted={muted} ref={lightSwitchAudio} src={require('../../../assets/audio/light-switch.mp3')} />
      <audio paused="paused" muted={muted} ref={emailAlertAudio} src={require('../../../assets/audio/email-alert.mp3')} />
      <audio paused="paused" muted={muted} ref={coffeeDrinkAudio} src={require('../../../assets/audio/coffee-drink.mp3')} />
      <audio paused="paused" muted={muted} ref={outgoingRingAudio} src={require('../../../assets/audio/phone-ringing-outgoing.mp3')}/>
      <audio paused="paused" muted={muted} ref={phoneVibratingAudio} src={require('../../../assets/audio/phone-vibrating.mp3')} />
    </>
  )
};

export default AudioEffect;