import {useCallback, useRef, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import Typewriter from 'typewriter-effect';
import {setFlags} from '../../../redux/slices/narrativeSlice';
import Monitor from '../../Elements/DeviceWrappers/Monitor';

import './styles.scss';

const stages = [
  'delay',
  'amount',
  'customer'
];

const CreateOrder = () => {
  const dispatch = useDispatch();
  const typingTimer = useRef(null);
  const [currentStage, setCurrentStage] = useState(0);

  const incrementStageHandler = useCallback(() => {
    setCurrentStage(previous => {
      if(typingTimer.current && previous === stages.length -1) {
        clearInterval(typingTimer.current);
      }
      return previous + 1;
    });
  }, []);

  useEffect(() => {
    if(!typingTimer.current) {
      typingTimer.current = setInterval(incrementStageHandler, 1200);
    }
    return () => {
      if(!typingTimer.current) {
        clearInterval(typingTimer.current);
      }
    }
  }, [incrementStageHandler]);

  const handleConfirmClick = () => {
    dispatch(setFlags(['WIDGET_CALL_ORDER_CREATED']));
  };
  
  return (
    <Monitor hideBack={true}>
      <div className="create-offer-window">
        <div className="create-amount-text">
        {currentStage === stages.findIndex(stage => stage === 'amount') ?  <Typewriter options={{
                  strings: '5000',
                  autoStart: true,
                  loop: false,
                  delay: 35,
                }} /> : null }
        {currentStage > stages.findIndex(stage => stage === 'amount') ?  (<p>5000</p>) : null }
        </div>
        <div className="create-customer-text">
          {currentStage === stages.findIndex(stage => stage === 'customer') ? <Typewriter options={{
                  strings: ['Clambones'],
                  autoStart: true,
                  loop: false,
                  delay: 35,
                }} /> : null }
          {currentStage > stages.findIndex(stage => stage === 'customer') ?  (<p>Clambones</p>) : null }
        </div>
        {currentStage === stages.length ? (<button onClick={handleConfirmClick} className="create-order-confirm">Create order</button>) : null}
      </div>
    </Monitor>
  );
};

export default CreateOrder;