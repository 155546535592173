import {useEffect, useCallback} from 'react';
import {useDispatch} from 'react-redux';
import {resetAllFlags} from '../../../redux/slices/narrativeSlice';
import {resetApp, hideOnePagerLink} from '../../../redux/slices/appSlice';
import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon  } from 'react-share';

import ScamHeader from '../../Elements/ScamHeader';
import './styles.scss';

const Summary = props => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideOnePagerLink());

  }, [dispatch,]);

  const handleReset = useCallback(() => {
    dispatch(resetAllFlags());
    dispatch(resetApp());
  }, [dispatch]);

  return (<div className="summary-wrapper">
    <ScamHeader shadow={true} text="Are you scam savvy?" />
    <div className="summary-content">
      <h2>Congratulations on completing the Take Five fraud exercise!</h2>
      <a className="button" alt="Take five link" href="https://www.takefive-stopfraud.org.uk/">Click here to learn more about all the information covered in this experience.</a>
      <p>Share the challenge:</p>
      <ul className="social-list">
        <li>
          <FacebookShareButton url="https://businessquiz.takefive-stopfraud.org.uk/" quote="Are you scam savvy? Take the Amazon/UK Finance SME fraud quiz to find out">
            <FacebookIcon  iconFillColor={'#ffffff'} bgStyle={{fill: '#cb6805'}}  size={32} borderRadius={20}  />
          </FacebookShareButton>
        </li>
        <li>
          <TwitterShareButton url="https://businessquiz.takefive-stopfraud.org.uk/" title="Are you scam savvy? Take the Amazon/UK Finance SME fraud quiz to find out">
            <TwitterIcon  iconFillColor={'#ffffff'} bgStyle={{fill: '#cb6805'}}  size={32} borderRadius={20} />
          </TwitterShareButton>
        </li>
        <li>
          <a href='https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fbusinessquiz.takefive-stopfraud.org.uk'>
            <LinkedinIcon iconFillColor={'#ffffff'} bgStyle={{fill: '#cb6805'}}  size={32} borderRadius={20} />
          </a>
        </li>
      </ul>
      <br />
      <br />
      <button onClick={handleReset} className="button">Take the challenge again</button>
    </div>
  </div>)
};

export default Summary;
