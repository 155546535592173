const emails = [
  { 
    id: 'cb9da670-76e9-47f5-ab84-e4b8cd334d99',       
    subject: 'Don’t fall victim to fraud',
    from: 'Savvy SME <info@savvys.me>',
    time: {
      hours: 8,
      minutes: 47
    },
    body: '<p>Dear colleague,</p><p>Would you fall for a fraud or scam? Do you know how to check whether a payment request is genuine? This exercise shows how criminals target people and businesses, hoping that their guard is let down for just a moment.</p><p>Playing the part of the newest employee of Fine Widgets Ltd, you’ll get a series of simulated emails and phone messages, some of which will be genuine, and some not. Keep an eye out for the signs of a fraud or scam.</p><p>Check out the one-page introduction to stopping fraud on your desk so you know what to look for.</p><p>Good luck!</p>',
    requiredCounters: [],
    requiredFlags: [['OVERLAY_TRIGGERED_7431f99f-8505-489f-b91d-f8e30bb4b899']],
    options: [
      {
        label: 'Read the one pager',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['INTRO_EMAIL_TRIGGERED'],
        hideFlags: [['INTRO_EMAIL_TRIGGERED'], ['ONE_PAGER_READ']],
      }
    ]
  },
  {
    id: 'd8a57dca-4548-452c-8c96-e377a4add9f6',
    subject: 'Urgent payment required',
    from: 'Roberta Flanagan',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>Morning!</p><p>Sorry I\'m too busy to call, but could you transfer £7,500 to the bank account below? It\'s part payment for some upcoming work the company\'s doing on our behalf.</p><p>Account number: 98940303<br /> Sort code: 16-04-60<br />Account name: WAZT Securities Ltd</p><p>Thanks,<br />Roberta</p><p>CEO, Fine Widgets Ltd.</p>',
    requiredCounters: [],
    requiredFlags: [['ONE_PAGER_READ']],
    options: [
      {
        label: 'Make the payment',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['WAZT_PAYMENT_MAKE', 'WAZT_PAYMENT_CHOSEN'],
        hideFlags: [['WAZT_PAYMENT_CHOSEN']],
      },
      {
        label: 'Search online for ‘WAZT Securities Ltd’',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['WAZT_PAYMENT_SEARCH'],
        hideFlags: [['WAZT_PAYMENT_SEARCH'], ['WAZT_PAYMENT_CHOSEN']],
      },
      {
        label: 'Call Roberta',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['WAZT_PAYMENT_CALL'],
        hideFlags: [['WAZT_PAYMENT_CALL'], ['WAZT_PAYMENT_CHOSEN']],
      },
      {
        label: 'Do nothing',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['WAZT_PAYMENT_NOTHING', 'WAZT_PAYMENT_CHOSEN'],
        hideFlags: [['WAZT_PAYMENT_CHOSEN']],
      },
    ],
  },
  {
    id: '44ef9e51-8890-4fad-b58e-e660771b1ba1',
    subject: 'Heading in soon',
    from: 'Roberta Flanagan',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>Sorry I didn\'t make it in for our 8.30am. It\'s just far too early in the morning to talk about spreadsheets.<br />Should be in the office any minute now...</p><p>Thanks,<br />Roberta</p>',
    requiredCounters: [],
    requiredFlags: [['BACK_FROM_LUNCH_DAY_1']],
    options: [],
  },
  {
    id: '30f198d8-96fa-4bde-83e2-e862180fe1e7',
    subject: 'Work devices',
    from: 'IT Admin',
    time: {
      hours: 9,
      minutes: 12
    },
    body:"<p>Could I please remind everyone of four VERY important IT rules?</p><p><strong>ONE</strong> please close down your computer properly when you’re done for the day. It keeps your computer secure, saves us some money and helps the planet too - what's not to love?</p><p><strong>TWO</strong> try and resist the urge to click ‘not now’ when your computer asks to apply updates or at least not more than a couple of days in a row. If we can’t run updates, we - and you - aren’t protected.</p><p><strong>THREE</strong> please don’t use the same password on your work and personal devices – in fact, try and use a different password each time. There are password managers that can help you remember them and stay secure at the same time.</p><p><strong>FOUR</strong> company phones should be on silent while in the office.</p><p>Your friendly network admin</p>",
    requiredCounters: [],
    requiredFlags: [['BACK_FROM_LUNCH_DAY_1']],
    options: [],
  },
  {
    id: 'db803590-ca53-4d8b-bf50-c093dc20adfe',
    subject: 'Yesterday\'s orders',
    from: 'Jo \'Warehouse\' Green',
    time: {
      hours: 9,
      minutes: 13
    },
    body:'<p>Pretty standard set of orders yesterday but I know you like a list so here goes:</p><ul><li>2,000 to ACME UNDERTAKINGS (£10,000)</li><li>1,400 to BUMPZ & CO (£7,700)</li><li>40 to CLAMBONES (£400 incl delivery - someone should tell these guys if they order more than a few at a go they\'ll get them way cheaper!)</li><li>3,000 to DREAMUP INDUSTRIES (£6,000 v special offer signed off by Roberta)</li></ul><p>All Type A widgets. If we ever get an order for the type Bs I\'ll let you know.</p><p>Jo (Warehouse Jo)</p>',
    requiredCounters: [],
    requiredFlags: [['BACK_FROM_LUNCH_DAY_1']],
    options: [],
  },
  {
    id: 'c0248c11-ff3c-4612-b320-9ef075b56d7c',
    subject: 'Sorry!',
    from: 'Roberta Flanagan',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>FYI - I\'m getting some complaints from the team about all these checks; do you really need to double check every order, payment and invoice? It’s affecting their sales as everything slows down while you check. </p><p>Speak soon,</p><p>R</p>',
    requiredCounters: [],
    requiredFlags: [['DAY_2_STARTS']],
    options: [],
  },
  {
    id: '9be1733e-8d88-41b1-ac87-be6a4ba75eef',
    subject: 'Thank you',
    from: 'Admin',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>Thanks everyone for shutting off your PCs and installing their updates yesterday.</p><p>To those that haven’t done it - please do it today and you’ll never have to see another email like this again.</p><p>Your friendly network admin</p>',
    requiredCounters: [],
    requiredFlags: [['DAY_2_STARTS']],
    options: [],
  },
  {
    id: '3704dc9d-ad9c-40f2-bad7-933ea99055ee',
    subject: 'Yesterday\'s orders',
    from: 'Jo \'Warehouse\' Green',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>Yesterday we delivered:</p><ul><li>None to ACME UNDERTAKINGS (£0)</li><li>3,000 to BUMPZ & CO (£14,440)</li><li>5,000 to CLAMBONES (£20,000 - WOW!!)</li><li>1,000 to ELEPHA INC (£5,000)</li><li>Type As across the board.</li></ul><p>Hope you\'re well,<br />Jo (Warehouse Jo)</p>',
    requiredCounters: [],
    requiredFlags: [['DAY_2_STARTS', 'WIDGET_CALL_AGREE']],
    options: [],
  },
  {
    id: '8aabef8f-f52a-4c3f-973a-5e5e9330a8c3',
    subject: 'Yesterday\'s orders',
    from: 'Jo \'Warehouse\' Green',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>Yesterday we delivered:</p><ul><li>None to ACME UNDERTAKINGS (£0)</li><li>3,000 to BUMPZ & CO (£14,440)</li><li>0 to CLAMBONES (This is a bit strange, did something happen?)</li><li>1,000 to ELEPHA INC (£5,000)</li><li>Type As across the board.</li></ul><p>Hope you\'re well,<br />Jo (Warehouse Jo)</p>',
    requiredCounters: [],
    requiredFlags: [['DAY_2_STARTS', 'WIDGET_CALL_REFUSE']],
    options: [],
  },
  {
    id: '1b8bd368-d0ce-487c-9626-f61f66c72004',
    subject: 'Invoice for services in January 2022',
    from: '<justin@preparethewidget.io>',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>Good morning,</p><p>Please find attached an invoice for £630 for immediate payment as per previous terms. This refers to work carried out in the previous calendar month for which timesheets have been submitted as agreed.</p><p> Kind regards,<br />Justin SB<br />Finance lead,<br />Prepare The Widget, PTY</p>',
    requiredCounters: [],
    requiredFlags: [['RESULT_VOUCHER']],
    options: [
      {
        label: 'Open finance software',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['INVOICE_OPENED'],
        hideFlags: [['INVOICE_COMPLETE']],
      },
    ],
  },
  {
    id: '5de9361e-bd71-4cfd-8f34-8d86fd0926d7',
    subject: 'Computer problem',
    from: 'IT Helpdesk',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>Thank you for taking my call.</p><p>Please click the link below and I can log in to your comptuer and fix the problem.</p>',
    requiredCounters: [],
    requiredFlags: [['IT_CALL_COMPLETE']],
    options: [
      {
        label: 'Click the link and give access',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['IT_ACCESS_GRANTED', 'IT_SELECTION_MADE'],
        hideFlags: [['IT_SELECTION_MADE']],
      },
      {
        label: 'Call your IT helpdesk on their usual number',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['IT_ACCESS_CALL', 'IT_SELECTION_MADE'],
        hideFlags: [['IT_SELECTION_MADE']],
      },
      {
        label: 'Don\'t reply',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['IT_ACCESS_IGNORE', 'IT_SELECTION_MADE', 'IT_COMPLETE'],
        hideFlags: [['IT_SELECTION_MADE']],
      },
    ],
    advice: {
      adviceLabel: 'it_takeover_advice'
    }
  },
  {
    id: '747b5e2d-7ab5-4a8c-95e3-a2c0204feb00',
    subject: 'Out of office: Me today',
    from: 'Roberta Flanagan',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>Sorry to miss your calls, texts and emails over the past few hours. I\'m currently out of the office and will be back in touch next week.</p><p>If I have arranged a meeting with you for today or tomorrow please accept my apologies.</p><p>With love to all,<br />Roberta S Flanagan<br />Chief Executive</p>',
    requiredCounters: [],
    requiredFlags: [['DAY_3_STARTS']],
    options: [],
  },
  {
    id: 'b2b5283f-3f2d-414e-a5fc-d4f64c9735e0',
    subject: 'Don\'t open this email',
    from: 'Admin',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>I thought reverse psychology might work, given you all seem to do the opposite of what I ask.</p><p>Something to think about over the weekend: I found a nasty virus on someone\'s personal laptop the other day. It had replaced every copy of every invoice with a picture of a baby elephant. Cute, but annoying. Luckily, we have backups.</p><p>Nobody else is using their personal devices for business purposes, right?</p><p>Yours in hope,<br />Your friendly network admin</p>',
    requiredCounters: [],
    requiredFlags: [['DAY_3_STARTS']],
    options: [],
  },
  {
    id: '26d6560e-c057-4a75-a99d-6ebef2971b91',
    subject: 'Orders update',
    from: 'Jo \'Warehouse\' Green',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>2,000 to ACME UNDERTAKINGS (£10,000)</p><ul><li>2,000 to BUMPZ & CO (£9,220)</li><li>200 to CLAMBONES (£2,000)</li><li>6,000 to FOREXICON (£30,000)</li></ul><p>That last one was all type Bs! I\'m kidding... type As across the board.</p><p>Speak soon,<br />Jo (Warehouse Jo)</p>',
    requiredCounters: [],
    requiredFlags: [['DAY_3_STARTS', 'INVOICE_ACCEPTED']],
    options: [],
  },
  {
    id: '590bbaec-9ea3-4e72-ac36-17175dad7ec5',
    subject: 'Orders update',
    from: 'Jo \'Warehouse\' Green',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>2,000 to ACME UNDERTAKINGS (£10,000)</p><ul><li>2,000 to BUMPZ & CO (£9,220)</li><li>200 to CLAMBONES (£2,000)</li><li>6,000 to FOREXICON (£30,000)</li></ul><p>That last one was all type Bs! I\'m kidding... type As across the board.</p><p>One thing I wanted to raise though - we didn\'t get enough stock from Prepared Widgets through this morning. Apparently, there\'s an issue with payment? Anyway, it\'s really caused us some issues and we\'re going to be late on the FOREXICON job - can you find out what happened there?</p><p>Speak soon,<br />Jo (Warehouse Jo)</p>',
    requiredCounters: [],
    requiredFlags: [['DAY_3_STARTS', 'INVOICE_REJECTED']],
    options: [],
  },
  {
    id: '4ed1ea8f-28a6-40a3-b0e7-a5b082106a66',
    subject: 'Change of bank details',
    from: '<team@supply-surprise.org>',
    time: {
      hours: 9,
      minutes: 12
    },
    body:'<p>To whom it may concern,</p><p>We are now handling our invoicing via a third party. Please change our bank details to the following:</p><p>ACC: 20340999</br>Sort code: 13-30-02<br />Name: Company Receivers Ltd</p><p>Kind regards,<br />The team at Supply Surprise</p>',
    requiredCounters: [],
    requiredFlags: [['EMAIL_TRIGGERED_747b5e2d-7ab5-4a8c-95e3-a2c0204feb00', 'EMAIL_TRIGGERED_b2b5283f-3f2d-414e-a5fc-d4f64c9735e0', 'EMAIL_TRIGGERED_26d6560e-c057-4a75-a99d-6ebef2971b91'],['EMAIL_TRIGGERED_747b5e2d-7ab5-4a8c-95e3-a2c0204feb00', 'EMAIL_TRIGGERED_b2b5283f-3f2d-414e-a5fc-d4f64c9735e0', 'EMAIL_TRIGGERED_590bbaec-9ea3-4e72-ac36-17175dad7ec5']],
    options: [
      {
        label: 'Change account details',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['ACCOUNT_CHANGE_ACCEPT'],
        hideFlags: [['ACCOUNT_CHANGE_ACCEPT'], ['ACCOUNT_CHANGE_IGNORE']],
      },
      {
        label: 'Ignore and report',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['ACCOUNT_CHANGE_IGNORE'],
        hideFlags: [['ACCOUNT_CHANGE_ACCEPT'], ['ACCOUNT_CHANGE_IGNORE']],
      },
    ],
    advice: {
      adviceLabel: 'detail_change_advice',
    },
  },
  {
    id: '172999ad-31cc-4de4-9fe9-a8eef4c29c98',
    subject: 'Notebooks',
    from: 'Marcus Thomas',
    time: {
      hours: 9,
      minutes: 12,
    },
    body: '<p>Hi – hope all is well! Roberta said you might be just the person to talk to about this. I need a whole bunch of notebooks for the diagrams department, and I need them really quickly.</p><p>I know we normally use A J Knurl Stationery for all this stuff, but they take so long - just this once can you get them somewhere else? I’ll send you the new supplier details – they just need £200 transferred across and they’ll send them out immediately.</p>',
    requiredCounters: [],
    requiredFlags: [['RESULT_DETAILS']],
    options: [
      {
        label: 'Order from the new supplier',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        setFlags: ['PROCURE_NEW_SUPPLIER'],
        hideFlags: [['PROCURE_NEW_SUPPLIER'], ['PROCURE_OLD_SUPPLIER']]
      },
      {
        label: 'Order from A J Knurl',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        setFlags: ['PROCURE_OLD_SUPPLIER'],
        hideFlags: [['PROCURE_NEW_SUPPLIER'], ['PROCURE_OLD_SUPPLIER']]
      },
    ]
  }
];

export default emails;