import BackButton from '../../BackButton';
import './styles.scss';

const Phone = props => {
  return (<div className="phone-wrapper">
    {!props.hideBack && <BackButton />}
    {props && props.children}
  </div>);
};

export default Phone;