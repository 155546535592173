import Monitor from '../../Elements/DeviceWrappers/Monitor';

import './styles.scss';

const AccountChange = props => {
  return (
    <Monitor hideBack={true}>
      <div className="finance-window-2">

      </div>
    </Monitor>
  );
};

export default AccountChange;