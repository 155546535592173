import {useCallback, useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Scrollbar} from 'react-scrollbars-custom';
import classNames from 'classnames';
import {useMobileView} from '../../styles/helper';
import {narrativeSelectors, markEmailRead} from '../../redux/slices/narrativeSlice';
import OptionsList from '../Elements/OptionsList';

import './styles.scss';
import Monitor from '../Elements/DeviceWrappers/Monitor';

const EmailReadingPane = ({currentEmail, handleCloseReadingPane, handleOptionClick, mobileView}) => {
  
  const readingPaneClasses = classNames('email-reading-pane', {
    'active-options': currentEmail.activeOptionCount > 0
  });
  return (<>
    <div className={readingPaneClasses}>
      <div className="email-subject"><p>{currentEmail.subject}</p><button onClick={handleCloseReadingPane} /></div>
      <div className="email-from">From: {currentEmail.from}</div>
      <Scrollbar noScrollX={true} style={{height: mobileView ? '67%' : '80%', width: mobileView ? '91%' : '95%'}}>
        <div className="email-body" dangerouslySetInnerHTML={{__html: currentEmail.body}} />
      </Scrollbar>
    </div>
    <OptionsList advice={currentEmail.advice} optionClickCallback={handleOptionClick} options={currentEmail.options} />
  </>);
};

const Email = props => {
  const mobileView = useMobileView();
  const dispatch = useDispatch();
  const emails = useSelector(narrativeSelectors.getCurrentEmails);
  const [currentEmailIndex, setCurrentEmailIndex] = useState(props && props.params && props.params.id);
  const [readingPaneOpen, setReadingPaneOpen] = useState(!!(props.target === 'email' && props.params.id && emails.find(email => email.id === currentEmailIndex)));


  const handleEmailClick = useCallback((emailIndex) => {
    const emailId = emails[emailIndex].id;
    setCurrentEmailIndex(emailId);
    setReadingPaneOpen(true);

  }, [setCurrentEmailIndex, setReadingPaneOpen, emails]);

  const closeReadingPane = useCallback(() => {
    if(currentEmailIndex)  {
      const currentEmail = emails.find(email => email.id === currentEmailIndex);
      if(currentEmail && !currentEmail.read) {
        dispatch(markEmailRead({emailId: currentEmailIndex}));
      } 
    }
    setReadingPaneOpen(false);
  }, [currentEmailIndex, emails, dispatch]);

  const handleEscapePress = useCallback(event => {
    const {keyCode} = event;
    if(keyCode === 27 && readingPaneOpen){
      closeReadingPane();
    }
  }, [readingPaneOpen, closeReadingPane]);

  const handleOptionClick = useCallback(() => {
    closeReadingPane();
  }, [closeReadingPane]);

  const handleCloseReadingPane = () => closeReadingPane();

  const handleBackButton = useCallback(() => {
    if(readingPaneOpen) {
      closeReadingPane();
    }
  }, [readingPaneOpen, closeReadingPane]);

  useEffect(() => {
    window.addEventListener('keydown', handleEscapePress);
    return () => {
      window.removeEventListener('keydown', handleEscapePress)
    };
  }, [handleEscapePress])

  

  const currentEmail = emails.find(email => email.id === currentEmailIndex);


  return(<>
    <Monitor backButtonProps={{ignoreDefaultHandler: readingPaneOpen, onClick: handleBackButton}}>
      <div className="email-client">
        <div className="email-topbar" />
        <div className="email-sidebar" />
        <h1 className="email-list-title">My Inbox</h1>
        <ul className="email-list">
          {emails && emails.map((email, emailIndex) => {
            const emailClasses = classNames('email-item', {
              unread: !email.read,
              active: currentEmailIndex === email.id,
            });
            return (<li onClick={() => handleEmailClick(emailIndex)} className={emailClasses} key={`email-${email.id}`}>
              <p className="email-from">{email.from}</p>
              <p className="email-subject">{email.subject}</p>
            </li>);
          })}
        </ul>
      </div>
      {readingPaneOpen && currentEmail && <EmailReadingPane currentEmail={currentEmail} handleCloseReadingPane={handleCloseReadingPane} handleOptionClick={handleOptionClick} mobileView={mobileView} />}
    </Monitor>
  </>);
};

export default Email;