import {useCallback, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import OptionsList from '../Elements/OptionsList';
import Subtitles from '../Elements/Subtitles';
import Phone from '../Elements/DeviceWrappers/Phone';
import {narrativeSelectors, setFlags} from '../../redux/slices/narrativeSlice';

import './styles.scss';

const Call = props => {
  const [currentSubtitle, setCurrentSubtitle] = useState(undefined);
  const currentCalls = useSelector(narrativeSelectors.getCurrentCalls);
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if(currentCalls && currentCalls.length === 1 && !currentCalls[0].ringing) {
  //     // setCurrentSubtitle(0);
  //   }
  // }, [currentCalls.id])

  const handleAnswerCall = useCallback((answerFlags = []) => {
    dispatch(setFlags(answerFlags));
  }, [dispatch]);

  const handleSubtitlesInit = useCallback(() => {
    setCurrentSubtitle(0);
  }, []);

  const handleSubtitlesChanged = useCallback((subtitleIndex) => {
    setCurrentSubtitle(subtitleIndex);
  }, [])

  return(<Phone>
    {currentCalls && currentCalls.length === 1 && !currentCalls[0].ringing && (
      <>
        <div className="call-inner call-details">
          <p className="call-name">{currentCalls[0].calling}</p>
          <img className="call-avatar" alt="avatar" src={require('../../assets/img/icon-avatar.png')} />
        </div>
        <div className="call-control">
          <Subtitles onChange={handleSubtitlesChanged} onInit={handleSubtitlesInit} subtitles={currentCalls[0].subtitles} />
          {currentSubtitle !== undefined && currentSubtitle === currentCalls[0].subtitles.length - 1 && <OptionsList advice={currentCalls[0].advice} options={currentCalls[0].options} />}
        </div>
      </>
    )}

    {currentCalls && currentCalls.length === 1 && currentCalls[0].ringing && (
      <div className="call-inner call-ringing">
        <p className="call-name">{currentCalls[0].calling}</p>
        <img className="call-avatar" alt="avatar" src={require('../../assets/img/icon-avatar.png')} />
        <div className="call-ringing-wrapper">
          <div className="call-ringing-circle outer-circle" />
          <div className="call-ringing-circle inner-circle" />
          <button className="call-answer" onClick={() => handleAnswerCall(currentCalls[0].answerSetFlags)} />
        </div>
      </div>
    )}
    </Phone>
  );
};

export default Call;