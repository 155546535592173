import './styles.scss';
import Typewriter from 'typewriter-effect';

const VoucherPurchase = props => {
  return (
    <div className="voucher-purchase-wrapper">
      <div className="email-body">
      <Typewriter options={{
                  strings: 'Hi Rita, I purchased the £100 of gift vouchers you asked for.  Here are the links: https://voucher.com/1384938, https://voucher.com/9283928, https://voucher.com/12384a',
                  autoStart: true,
                  loop: false,
                  delay: 35,
                }} /> 
      </div>
    </div>
  );
};

export default VoucherPurchase;