import {useCallback, useState, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {Scrollbar} from 'react-scrollbars-custom';
import {hideAdvice} from '../../redux/slices/appSlice';
import {setAdviceTriggered} from '../../redux/slices/narrativeSlice';

import BackButton from '../Elements/BackButton';
import ScamHeader from '../Elements/ScamHeader';


import './styles.scss';

const Advice = ({advice}) => {
  const dispatch = useDispatch();
  const [scrolled, setScrolled] = useState(false);

  const handleBackClick = useCallback(() => {
    dispatch(hideAdvice());
  }, [dispatch]);

  const handleScroll = useCallback(() => {
    setScrolled(true);
  }, []);

  useEffect(() => {
    dispatch(setAdviceTriggered({adviceId: advice.id}))
  }, [advice, dispatch]);

  return (<div className="advice-wrapper">
    <div className="advice-overlay" />
    <div className="advice-inner-wrapper">
    <Scrollbar onScroll={handleScroll} noScrollX={true} style={{height: '100%', width: '100%'}}>
      <ScamHeader shadow={false} text={advice.title || 'Advice'} />
      <div className="advice-content" dangerouslySetInnerHTML={{__html: advice.text}}>
      </div>
      {!scrolled && <div className="scroll-prompt"><span className="ca3-scroll-down-arrow"></span></div>}
      </Scrollbar>
    </div>
    <BackButton ignoreDefaultHandler={true} onClick={handleBackClick} />
  </div>
);
};

export default Advice;