import emails from './emails';
import calls from './calls'
import events from './events';
import messages from './messages';
import focus from './focus';
import backgrounds from './backgrounds';
import results from './results';
import advice from './advice';
import overlays from './overlays';
import teabreaks from './teabreaks';

const narrative = {
  narrativeData: [
    ...advice.map(adviceItem => {return {...adviceItem, narrativeType: 'advice'};}),
    ...emails.map(email => { return {...email, narrativeType: 'email' };}),
    ...calls.map(call => { return {...call, narrativeType: 'call' };}),
    ...events.map(event => { return {...event, narrativeType: 'event' };}),
    ...messages.map(message => { return {...message, narrativeType: 'message' };}),
    ...focus.map(focus => { return {...focus, narrativeType: 'focus' };}),
    ...backgrounds.map(background => { return {...background, narrativeType: 'background'};}),
    ...results.map(result => { return {...result, narrativeType: 'result'};}),
    ...advice.map(advice => { return {...advice, narrativeType: 'advice'};}),
    ...overlays.map(overlay => { return {...overlay, narrativeType: 'overlay'};}),
    ...teabreaks.map(teabreak => { return {...teabreak, narrativeType: 'teabreak'};}),
  ]
};

export default narrative;
