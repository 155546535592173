const calls = [
  {
    id: '8093718f-d58c-4f63-8059-2842001d630e',
    calling: 'Unknown number',
    avatar: null,
    audio: null,
    subtitles: [],
    ringing: true,
    requiredFlags: [['EMAIL_TRIGGERED_44ef9e51-8890-4fad-b58e-e660771b1ba1', 'EMAIL_TRIGGERED_30f198d8-96fa-4bde-83e2-e862180fe1e7', 'EMAIL_TRIGGERED_db803590-ca53-4d8b-bf50-c093dc20adfe']],
    hideFlags: [['WIDGET_CALL_ANSWERED']],
    options: [],
    answerSetFlags: ['WIDGET_CALL_ANSWERED'],
  },
  {
    id: 'abc63bd4-c15e-4661-86a8-301946e82cfa',
    calling: 'Unknown number',
    avatar: null,
    audio: null,
    subtitles: [
      {
        speaker: null,
        text: 'Hi, is this the widgets company?  I\'m calling from Clambones.',
        images: [],
      },
      {
        speaker: null,
        text: 'I normally speak to a colleague of yours but someone gave me your number.',
        images: [],
      },
      {
        speaker: null,
        text: 'Can we order 5,000 on our normal payment terms? Great.',
        images: [],
      }
    ],
    requiredFlags: [['WIDGET_CALL_ANSWERED']],
    // requiredFlags: [['EMAIL_TRIGGERED_44ef9e51-8890-4fad-b58e-e660771b1ba1', 'EMAIL_TRIGGERED_c49006ea-5b05-4e42-b8e1-1934bd67d321', 'EMAIL_TRIGGERED_30f198d8-96fa-4bde-83e2-e862180fe1e7', 'EMAIL_TRIGGERED_db803590-ca53-4d8b-bf50-c093dc20adfe']],
    hideFlags: [['WIDGET_CALL_AGREE'], ['WIDGET_CALL_CHALLENGE'],['WIDGET_CALL_EMAIL'], ['WIDGET_CALL_FILL_FORM']],
    options: [
      {
        label: 'Yes of course',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['WIDGET_CALL_AGREE',],

      },
      {
        label: 'Sorry, who is this?',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['WIDGET_CALL_CHALLENGE'],

      },
      {
        label: 'I\'ll email over an order form',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['WIDGET_CALL_FILL_FORM'],
      },
    ],
  },
  {
    id: '4dfe8a10-b0bc-455a-a565-4cd986ae9462',
    calling: 'Unknown number',
    avatar: null,
    audio: null,
    subtitles: [
      {
        speaker: null,
        text: 'Great, thanks!',
        images: [],
      },
    ],
    requiredFlags: [['WIDGET_CALL_AGREE']],
    hideFlags: [['WIDGET_CALL_COMPLETE']],
    options: [
      {
        label: 'Hang up',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['WIDGET_CALL_COMPLETE'],
      }
    ],
  },
  {
    id: '4dfe8a10-b0bc-455a-a565-4cd986ae9462',
    calling: 'Unknown number',
    avatar: null,
    audio: null,
    subtitles: [
      {
        speaker: null,
        text: 'What?? That\'s ridiculous.',
        images: [],
      },
      {
        speaker: null,
        text: 'You\'ll be hearing from us.',
        images: [],
      },
    ],
    requiredFlags: [['WIDGET_CALL_REFUSE']],
    hideFlags: [['WIDGET_CALL_COMPLETE']],
    options: [
      {
        label: 'Hang up',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['WIDGET_CALL_COMPLETE'],
      }
    ],
  },
  {
    id: '504961c6-7f67-43e1-b25e-15e9883d6014',
    calling: 'Unknown number',
    avatar: null,
    audio: null,
    subtitles: [
      {
        speaker: null,
        text: 'Oh, my name\'s Hiro.',
        images: [],
      },
      {
        speaker: null,
        text: 'I\'m new but I\'ve been approved to put the order in.',
        images: [],
      },
      {
        speaker: null,
        text: 'I\'m really busy, can you confirm the order please?',
        images: [],
      }
    ],
    requiredFlags: [['WIDGET_CALL_CHALLENGE']],
    hideFlags: [['WIDGET_CALL_REFUSE'], ['WIDGET_CALL_AGREE'], ['WIDGET_CALL_FILL_FORM'] ],
    options: [
      {
        label: 'Yes that\'s fine',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['WIDGET_CALL_AGREE'],
      },
      {
        label: 'You\'re going to need to send an order form',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['WIDGET_CALL_FILL_FORM'],
      },
      {
        label: 'Sorry, I can\'t take an order',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['WIDGET_CALL_REFUSE'],
      },
    ],
  },
  {
    id: '6553d39a-d9bf-4c2e-8728-8b88c6769cc0',
    calling: 'Unknown number',
    avatar: null,
    audio: null,
    subtitles: [
      {
        speaker: null,
        text: 'Oh my computer\'s not working at the moment.',
        images: [],
      },
      {
        speaker: null,
        text: 'Can you just place the order for me?',
        images: [],
      },
      {
        speaker: null,
        text: 'I know we have an account.',
        images: [],
      },
    ],
    requiredFlags: [['WIDGET_CALL_FILL_FORM']],
    hideFlags: [['WIDGET_CALL_REFUSE'], ['WIDGET_CALL_AGREE'], ['WIDGET_CALL_COMPLETE'] ],
    options: [
      {
        label: 'Yes that\'s fine',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['WIDGET_CALL_AGREE'],
      },
      {
        label: 'Sorry, I can\'t take an order without a form',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['WIDGET_CALL_REFUSE'],
      },
    ],
  },
  {
    id: '5836e0a3-4b9d-4a9c-bac5-6ae786f1c779',
    calling: 'Voicemail',
    avatar: null,
    audio: null,
    subtitles: [],
    requiredFlags: [['EMAIL_TRIGGERED_c0248c11-ff3c-4612-b320-9ef075b56d7c', 'EMAIL_TRIGGERED_9be1733e-8d88-41b1-ac87-be6a4ba75eef', 'EMAIL_TRIGGERED_3704dc9d-ad9c-40f2-bad7-933ea99055ee'],['EMAIL_TRIGGERED_c0248c11-ff3c-4612-b320-9ef075b56d7c', 'EMAIL_TRIGGERED_9be1733e-8d88-41b1-ac87-be6a4ba75eef', 'EMAIL_TRIGGERED_8aabef8f-f52a-4c3f-973a-5e5e9330a8c3']],
    hideFlags: [['VOICEMAIL_ANSWERED']],
    options: [],
    ringing: true,
    answerSetFlags: ['VOICEMAIL_ANSWERED'],
  },
  {
    id: '6553d39a-d9bf-4c2e-8728-8b88c6769cc0',
    calling: 'Voicemail',
    avatar: null,
    audio: null,
    subtitles: [
      {
        speaker: null,
        text: 'Hi it\'s Roberta. I tried to call but it didn\'t connect.',
        images: [],
      },
      {
        speaker: null,
        text: 'Could you do me a favour? I\'ve locked myself out of the bank software',
        images: [],
      },
      {
        speaker: null,
        text: 'and it\'s pestering me to approve payroll for the month.',
        images: [],
      },
      {
        speaker: null,
        text: 'Could you send me your login details? Really appreciate it - thanks!',
        images: [],
      },
    ],
    requiredFlags: [['VOICEMAIL_ANSWERED']],
    hideFlags: [['VOICEMAIL_COMPLETE']],
    options: [
      {
        label: 'Send your login details',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['VOICEMAIL_COMPLETE', 'VOICEMAIL_LOGIN_AGREE'],
      },
      {
        label: 'Refuse to send your login details',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['VOICEMAIL_COMPLETE', 'VOICEMAIL_LOGIN_REFUSE'],
      },
      {
        label: 'Ignore the voicemail',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['VOICEMAIL_COMPLETE', 'VOICEMAIL_LOGIN_REFUSE_IGNORE'],
      },
    ],
    advice: {
        adviceLabel: 'impersonation_advice'
    }
  },
  {
    id: 'c78c1018-375a-47c1-8dd6-46efdf0c6477',
    calling: 'IT Helpdesk',
    avatar: null,
    audio: null,
    subtitles: [],
    requiredFlags: [['RESULT_INVOICE']],
    hideFlags: [['IT_CALL_ANSWERED']],
    options: [],
    ringing: true,
    answerSetFlags: ['IT_CALL_ANSWERED'],
  },
  {
    id: '09da1dc7-501b-4f60-8cbd-8553942bf66c',
    calling: 'IT Helpdesk',
    avatar: null,
    audio: null,
    subtitles: [
      {
        speaker: null,
        text: 'Hello, this is your IT helpdesk.',
        images: [],
      },
      {
        speaker: null,
        text: 'We\'ve spotted some strange activity on your computer so will need to take it over.',
        images: [],
      },
      {
        speaker: null,
        text: 'Could you please log in and click a link in the email I\'m sending you now?',
        images: [],
      },
    ],
    requiredFlags: [['IT_CALL_ANSWERED']],
    hideFlags: [['IT_CALL_COMPLETE']],
    options: [
      {
        label: 'Hang up',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['IT_CALL_COMPLETE'],
      },
    ],
  },
  {
    id: '0daa552a-25e5-47cb-83f4-8dd5e3ccdf16',
    calling: 'IT Helpdesk',
    avatar: null,
    audio: null,
    subtitles: [
      {
        speaker: null,
        text: 'What? No, that\'s not us. Please ignore it.',
        images: [],
      },
    ],
    requiredFlags: [['IT_ACCESS_CALL', 'IT_SELECTION_MADE']],
    hideFlags: [['IT_COMPLETE']],
    options: [
      {
        label: 'Hang up',
        requiredCounters: [],
        setCounters: [],
        requiredFlags: [],
        hideFlags: [],
        setFlags: ['IT_COMPLETE'],
      },
    ],
  },
];

export default calls;