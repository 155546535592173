import {createSlice} from '@reduxjs/toolkit';
import {v4 as uuidv4} from 'uuid';
import {narrativeSelectors} from './narrativeSlice';

const initialState = {
  time: {
    hours: '9',
    minutes: '0'
  },
  focus: {
    target: 'desk',
    params: {},
  },
  previousFocusTarget: null,
  showOnePagerLink: false,
  lampOn: false,
  lightOn: false,
  coffee: false,  // THIS FLAG IS JUST USED TO TRIGGER AUDIO
  clock: false,  // THIS FLAG IS JUST USED TO TRIGGER AUDIO
  outgoingRing: false, // THIS FLAG IS JUST USED TO TRIGGER AUDIO
  phoneVibrating: false, // THIS FLAG IS JUST USED TO TRIGGER AUDIO
  adviceVisible: false,
  adviceLabel: undefined,
  muted: false,
  sessionId: undefined,
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetApp: (state, action) => {
      state.time = {...initialState.time};
      state.focus = {...initialState.focus};
      state.showOnePagerLink = initialState.showOnePagerLink;
      state.lampOn = initialState.lampOn;
      state.lightOn = initialState.lightOn;
      state.coffee = initialState.coffee;
      state.clock = initialState.clock;
      state.outgoingRing = initialState.outgoingRing;
      state.phoneVibrating = initialState.phoneVibrating;
      state.adviceVisible = initialState.adviceVisible;
      state.adviceLabel = initialState.adviceLabel;
      state.muted = initialState.muted;
    },
    setFocus: (state, action) => {
      const {target, params} = action.payload;
      if(target) {
        state.previousFocusTarget = state.focus.target;
        state.focus = {
          target,
          params
        };
      }
    },
    setTime: (state, action) => {
      const {hours, minutes} = action.payload;
      if(hours !== undefined && minutes !== undefined) {
        state.time = {
          hours,
          minutes,
        };
      }
    },
    showOnePagerLink: (state,) => {
      state.showOnePagerLink = true;
    },
    hideOnePagerLink: (state,) => {
      state.showOnePagerLink = false;
    },
    toggleLamp: (state,) => {
      state.lampOn = !state.lampOn;
    },
    toggleLight: (state,) => {
      state.lightOn = !state.lightOn;
    },
    toggleCoffee: (state,) => {
      state.coffee = !state.coffee;
    },
    toggleClock: (state,) => {
      state.clock = !state.clock;
    },
    setOutgoingRing: (state, action) => {
      state.outgoingRing = action.payload;
    },
    setPhoneVibrating: (state, action) => {
      state.phoneVibrating = action.payload;
    },
    setAdviceVisible: (state) => {
      state.adviceVisible = true;
    },
    setCurrentAdvice: (state, action) => {
      state.adviceLabel = action.payload;
    },
    hideAdvice: (state, action) => {
      state.adviceVisible = false;
      state.adviceLabel = undefined;
    },
    toggleMute: (state, action) => {
      state.muted = !state.muted;
    },
    setSessionId: (state) => {
      state.sessionId = uuidv4();
    }
  }
});

export const showAdvice = (adviceLabel) => (dispatch, getState) => {
  const state = getState();
  if(narrativeSelectors.getAdviceExistsByLabel(state, adviceLabel)) {
    dispatch(appSlice.actions.setCurrentAdvice(adviceLabel));
    dispatch(appSlice.actions.setAdviceVisible(true));
  }
};

// const setPreviousFocus = (dispatch, getState) => {
//   const currentState = getState();
//   if(currentState.)
// }

export const appSelectors = {
  getSessionId: state => state.app.sessionId,
  getMuted: state => state.app.muted,
  getOnePagerLinkVisible: state => state.app.showOnePagerLink,
  getFocus: state => state.app.focus,
  getPreviousFocusTarget: state => state.app.previousFocusTarget,
  getLampOn: state => state.app.lampOn,
  getLightOn: state => state.app.lightOn,
  getCoffee: state => state.app.coffee,
  getClock: state => state.app.clock,
  getOutgoingRing: state => state.app.outgoingRing,
  getPhoneVibrating: state => state.app.phoneVibrating,
  getCurrentAdvice: state => state.app.adviceVisible ? narrativeSelectors.getAdviceByLabel(state, state.app.adviceLabel) : false,
};

export const {setSessionId, hideAdvice, showOnePagerLink, hideOnePagerLink, setFocus, setTime, toggleMute, toggleLamp, toggleLight, toggleCoffee, toggleClock, setOutgoingRing, setPhoneVibrating, resetApp} = appSlice.actions;

export default appSlice.reducer;