const teabreaks = [
  {
    id: 'd3da2636-cf17-4bd3-9fa6-c6d8dcdb5013',
    requiredFlags: [['RESULT_IMPERSONATION']],
    hideFlags: [['VOUCHER_AGREE'], ['VOUCHER_REFUSE_1']],
    subtitles: [
      {
        speaker: 'Rita from HR',
        text: 'Oh hi!  I was hoping to bump into you on a tea break at some point',
        images: [],
      },
      {
        speaker: 'Rita from HR',
        text: 'Listen. I wonder if you could do me a massive favour?  I’ll owe you',
        images: [],
      },
      {
        speaker: 'Rita from HR',
        text: 'I forgot to renew my company credit card and it’s expired, but I still need to get some gift cards for the ‘best employee cat picture’ competition from last week. Lots of great pictures, lots of winners.',
        images: [],
      },
      {
        speaker: 'Rita from HR',
        text: 'See...',
        images: ['cat_1', 'cat_2', 'cat_3'],
      },
      {
        speaker: 'Rita from HR',
        text: 'Could you buy some, like maybe £100 worth, online and send the voucher codes to hr.widget.fulfilment@gmail.com? It would literally save my life.',
        images: [],
      },
    ],
    options: [
      {
        label: 'Buy vouchers',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['VOUCHER_AGREE'],
        hideFlags: [],
      },
      {
        label: 'Do not buy vouchers',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['VOUCHER_REFUSE_1'],
        hideFlags: [],
      }
    ],
    advice: {
      adviceLabel: 'gift_card_advice',
    },
  },
  {
    id: '565c81ee-3109-4fca-8e20-b2420f3f056a',
    requiredFlags: [['VOUCHER_REFUSE_1']],
    hideFlags: [['VOUCHER_AGREE'], ['VOUCHER_REFUSE_2']],
    subtitles: [
      {
        speaker: 'Rita from HR',
        text: 'Really?  You can’t be a little bit flexible?',
        images: [],
      },
      {
        speaker: 'Rita from HR',
        text: 'Are you seriously going to make me look stupid in front of accounting about the credit card, and let down all those cat-owners?',
        images: [],
      },
    ],
    options: [
      {
        label: 'Buy vouchers',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['VOUCHER_AGREE'],
        hideFlags: [],
      },
      {
        label: 'Do not buy vouchers',
        requiredCounters: [],
        setsCounters: [],
        requiredFlags: [],
        setFlags: ['VOUCHER_REFUSE_2'],
        hideFlags: [],
      }
    ],
    advice: {
      adviceLabel: 'gift_card_advice',
    },
  },
];

export default teabreaks;