const backgrounds = [
  {
    id: "4765f3e5-480d-4d33-9cac-c0457530f7cd",
    target: "default",
    backgroundIndex:  'bg_desktop_day_1_am',
    backgroundLabel: "Clear desk, day 1 AM",
    requiredFlags: [],
    hideFlags: [['BACK_FROM_LUNCH_DAY_1']]
  },
  {
    id: "816e4bfc-3feb-444c-a998-73c187eb7ac6",
    target: "default",
    backgroundIndex:  'bg_desktop_day_1_pm',
    backgroundLabel: "Messy desk, day 1 PM",
    requiredFlags: [['BACK_FROM_LUNCH_DAY_1']],
    hideFlags: [['DAY_2_STARTS']]
  },
  {
    id: "28e45e6f-40a8-4e91-821b-b56327378658",
    target: "default",
    backgroundIndex:  'bg_desktop_day_2_am',
    backgroundLabel: "Clean desk, day 2 AM",
    requiredFlags: [['DAY_2_STARTS']],
    hideFlags: [['RESULT_IMPERSONATION']]
  },
  {
    id: "84a7c518-ed5c-44f9-9780-414dd7958325",
    target: "default",
    backgroundIndex:  'bg_desktop_day_2_pm',
    backgroundLabel: "Messy desk, day 2 PM",
    requiredFlags: [['RESULT_IMPERSONATION']],
    hideFlags: [['DAY_3_STARTS']]
  },
  {
    id: "2eccb673-d74a-4566-9359-d3d7e321f89b",
    target: "default",
    backgroundIndex:  'bg_desktop_day_3_am',
    backgroundLabel: "Clean desk, day 3 AM",
    requiredFlags: [['DAY_3_STARTS']],
    hideFlags: [['RESULT_DETAILS']]
  },
  {
    id: "e276e462-0894-496d-b685-8e9d181bc5c8",
    target: "default",
    backgroundIndex:  'bg_desktop_day_3_pm',
    backgroundLabel: "Messy desk, day 3 PM",
    requiredFlags: [['RESULT_DETAILS']],
    hideFlags: [['CHALLENGE_COMPLETE']]
  }
];

export default backgrounds;