import {useCallback} from 'react';
import {useDispatch} from 'react-redux';
import ScrollBar from 'react-scrollbars-custom';
import ScamHeader from '../Elements/ScamHeader';
import {setFlags} from '../../redux/slices/narrativeSlice';

import './styles.scss';

const Result = ({id, label, text, closeFlag}) => {
  const dispatch = useDispatch();

  const handleCloseClick = useCallback(() => {
    dispatch(setFlags([closeFlag]));
  }, [dispatch, closeFlag]);

  return (<div className='result-wrapper'>
    <ScamHeader shadow={false} text="Are you scam savvy?" />
    <div className="content">
      <ScrollBar style={{height: '100%', width: '100%'}}>
        <div className="result-text" dangerouslySetInnerHTML={{__html: text}} />
        <div className="result-button-wrapper">
          <button onClick={handleCloseClick}>Close</button>
        </div>
      </ScrollBar>
    </div>
  </div>);
};

export default Result;