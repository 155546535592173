import Monitor from '../../Elements/DeviceWrappers/Monitor';

import './styles.scss'

const ProcureOld = props => {
  return (<Monitor hideBack={true}>
    <div className="browser-wrapper stationary-2">
      <div className="browser-url">
        <p>http://www.ez-stationary.net</p>
      </div>
      <div className="browser-inner">
        <div className="logo-wrapper"><img alt="EZ stationary" src={require('../../../assets/img/logo-aj-knurl.png')} /></div>
        <p>Welcome back Fine Widgets.  Your default payment and delivery details have been added to this order.</p>
        <p>Your current order:</p>
        <table className="cart">
          <thead>
            <tr>
              <th>Item</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Subtotal</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Notebooks</td>
              <td>20</td>
              <td>£10.00</td>
              <td>£200.00</td>
            </tr>
            <tr>
              <td colSpan="2"></td>
              <td>VAT</td>
              <td>£33.33</td>
            </tr>
            <tr>
              <td colSpan="2"></td>
              <td>Grand Total</td>
              <td>£200.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Monitor>);
};

export default ProcureOld;