const focus = [
  {
    id: "c2784b01-2afe-4e6b-8e2d-39d7bd558d42",
    requiredFlags: [],
    target: 'event',
    params: {},
  },
  {
    id: "faccd1cf-489a-42ae-9b5f-4ba81f93200b",
    requiredFlags: [['GAME_STARTED']],
    target: 'default',
    params: {},
  },
  {
    id: "ccad1f52-2036-43cb-bc7b-ac0d6ef2757d",
    requiredFlags: [['EMAIL_TRIGGERED_cb9da670-76e9-47f5-ab84-e4b8cd334d99']],
    target: 'event',
    params: {},
  },
  {
    id: "75d1e764-a7fa-45b8-8c23-5ef34ae42994",
    requiredFlags: [['ONE_PAGER_READ']],
    target: 'desk',
    params: {},
  },
  {
    id: '3cf71f3b-3492-48b7-aede-c1857df42428',
    requiredFlags: [['WAZT_PAYMENT_CALL']],
    target: 'event',
    params: {},
  },
  {
    id: '69759765-ba2c-47d1-a712-0100caf74c48',
    requiredFlags: [['WAZT_PAYMENT_SEARCH']],
    target: 'event',
    params: {},
  },
  {
    id: 'dc821c50-6e3e-4341-84fb-4ae2e9b1ac30',
    requiredFlags: [['WAZT_PAYMENT_CALL_COMPLETE']],
    target: 'email',
    params: {
      id: 'd8a57dca-4548-452c-8c96-e377a4add9f6'
    },
  },
  {
    id: 'cfc7a2b9-457f-4dfc-9fbe-fb84aafbcc12',
    requiredFlags: [['WAZT_PAYMENT_SEARCH_COMPLETE']],
    target: 'email',
    params: {
      id: 'd8a57dca-4548-452c-8c96-e377a4add9f6'
    },
  },
  {
    id: 'df6a31e8-d693-43ef-ba0f-43739ea8a451',
    requiredFlags: [['WAZT_PAYMENT_MAKE']],
    target: 'event',
    params: {},
    triggered: false
  },
  {
    id: '1c107273-411a-4149-bfb1-d00a8ef15af4',
    requiredFlags: [['WAZT_PAYMENT_SENT'], ['WAZT_PAYMENT_NOTHING']],
    target: 'default',
    params: {},
  },
  {
    id: '8f299df2-2053-41ab-beaf-dece186982af',
    requiredFlags: [['RESULT_FAKE_REQUEST']],
    target: 'event',
    params: {},
  },
  {
    id: 'cf969e96-e59e-47a0-85ad-558d83690a28',
    requiredFlags: [['BACK_FROM_LUNCH_DAY_1']],
    target: 'default',
    params: {},
  },
  {
    id: '79a5eb2e-5165-4a63-9a1f-259292bd0b80',
    requiredFlags: [['EMAIL_TRIGGERED_44ef9e51-8890-4fad-b58e-e660771b1ba1','EMAIL_TRIGGERED_30f198d8-96fa-4bde-83e2-e862180fe1e7','EMAIL_TRIGGERED_db803590-ca53-4d8b-bf50-c093dc20adfe']],
    target: 'default',
    params: {},
  },
  {
    id: 'a1779984-312a-48fc-9e4b-cedb1c2cea9e',
    requiredFlags: [['WIDGET_CALL_COMPLETE', 'WIDGET_CALL_AGREE']],
    target: 'event',
    params: {},
  },
  {
    id: '8aae115d-3ac9-4066-b4e0-65872565a006',
    requiredFlags: [['WIDGET_CALL_REFUSE', 'WIDGET_CALL_COMPLETE'], ['WIDGET_CALL_ORDER_CREATED']],
    target: 'default',
    params: {},
  },
  {
    id: '8a672ec7-a877-41e7-b472-8a34bd908f41',
    requiredFlags: [['RESULT_FAKE_ORDER']],
    target: 'event',
    params: {},
  },
  {
    id: '63868bc1-a55a-4df8-933f-1a63dd81f50c',
    requiredFlags: [['DAY_2_STARTS']],
    target: 'default',
    params: {},
  },
  {
    id: 'cb4b1484-b5f2-458a-87d4-8d2525232c11',
    requiredFlags: [['EMAIL_TRIGGERED_c0248c11-ff3c-4612-b320-9ef075b56d7c', 'EMAIL_TRIGGERED_9be1733e-8d88-41b1-ac87-be6a4ba75eef', 'EMAIL_TRIGGERED_3704dc9d-ad9c-40f2-bad7-933ea99055ee'],['EMAIL_TRIGGERED_c0248c11-ff3c-4612-b320-9ef075b56d7c', 'EMAIL_TRIGGERED_9be1733e-8d88-41b1-ac87-be6a4ba75eef', 'EMAIL_TRIGGERED_8aabef8f-f52a-4c3f-973a-5e5e9330a8c3']],
    target: 'default',
    params: {}
  },
  {
    id: '6199038d-5fb4-40b5-b20a-7d8b839ddb91',
    requiredFlags: [['VOICEMAIL_LOGIN_REFUSE'], ['VOICEMAIL_LOGIN_AGREE']],
    target: 'message',
    params: {
      contact: 'Roberta'
    },
  },
  {
    id: '4775cba8-087b-4872-8120-c63457b749b9',
    requiredFlags: [['RESULT_IMPERSONATION']],
    target: 'teabreak',
    params: {},
  },
  {
    id: '2f40ee29-40cc-4169-869f-29d226a27588',
    requiredFlags: [['VOUCHER_AGREE']],
    target: 'event',
    params: {},
  },
  {
    id: '9d6fce86-3d69-4749-b46f-8e5fdeee7b16',
    requiredFlags: [['VOUCHER_PURCHASE_COMPLETE'], ['VOUCHER_REFUSE_2']],
    target: 'default',
    params: {},
  },
  {
    id: '30def997-12dc-42f3-8354-d76a55d52685',
    requiredFlags: [['INVOICE_OPENED']],
    target: 'event',
    params: {},
  },
  {
    id: '785acae5-84aa-4598-aa9b-77ad61a94ca4',
    requiredFlags: [['INVOICE_ACCEPTED'], ['INVOICE_REJECTED']],
    target: 'default',
    params: {},
  },
  {
    id: 'd42991a8-30fe-4241-8be8-a0df9d0e6881',
    requiredFlags: [['IT_CALL_COMPLETE']],
    target: 'default',
    params: {},
  },
  {
    id: '441c04f3-e872-4662-9354-73031a8c48fe',
    requiredFlags: [['IT_ACCESS_CALL']],
    target: 'call',
    params: {},
  },
  {
    id: 'f7d1658b-92b6-4558-ad68-31248d11be28',
    requiredFlags: [['IT_ACCESS_GRANTED']],
    target: 'event',
    params: {},
  },
  {
    id: 'fdd1ab05-e874-4ba7-a5bf-13747ea5a3c9',
    requiredFlags: [['IT_ACCESS_IGNORE'], ['IT_COMPLETE'], ['IT_TAKEOVER']],
    target: 'default',
    params: {},
  },
  {
    id: '36550b02-345a-4ee3-b41a-3e291960d053',
    requiredFlags: [['RESULT_IT']],
    target: 'event',
    params: {},
  },
  {
    id: 'ea5ef132-9235-4dcb-8f4b-a8d16a4d187c',
    requiredFlags: [['DAY_3_STARTS']],
    target: 'default',
    params: {},
  },
  {
    id: 'aa386aaa-9894-4b1e-8b84-f15ceac8fada',
    requiredFlags: [['ACCOUNT_CHANGE_ACCEPT']],
    target: 'event',
    params: {},
  },
  {
    id: 'fa631843-166f-4a52-9b09-5e3840beb8b9',
    requiredFlags: [['ACCOUNT_CHANGE_IGNORE'], ['ACCOUNT_CHANGE_COMPLETE']],
    target: 'default',
    params: {},
  },
  {
    id: '648f96e3-4fd7-4102-b565-d831ab038a3a',
    requiredFlags: [['PROCURE_NEW_SUPPLIER'], ['PROCURE_OLD_SUPPLIER']],
    target: 'event',
    params: {},
  },
  {
    id: 'c05cce0d-1c05-432d-8785-c882b26b3136',
    requiredFlags: [['PROCURE_COMPLETE']],
    target: 'default',
    params: {},
  },
  {
    id: 'aff6bd23-36c2-4b5e-8f1c-efd5fec83a50',
    requiredFlags: [['RESULT_PROCUREMENT']],
    target: 'event',
    params: {},
  }
];

export default focus;