import classNames from 'classnames';

import {ReactComponent as TakeFiveLogo} from '../../../assets/img/logo-take-five.svg';
import {ReactComponent as AmazonLogo} from '../../../assets/img/logo-amazon.svg';
import './styles.scss';

export const ScamHeader = ({text, shadow}) => {
  const headerClasses = classNames('scam-savvy-header', {
    shadow: shadow
  });
  return (<div className={headerClasses}>
            <div className="scam-savvy-left">
              <TakeFiveLogo />
              <h1>{text}</h1>
            </div>
            <div className="scamm-savvy-right">
              <AmazonLogo />
            </div>
          </div>);
};

export default ScamHeader;