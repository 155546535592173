import {useCallback, useEffect, useRef} from 'react';
import {useDispatch} from 'react-redux';
import {useMobileView} from '../../styles/helper';
import {setOverlayTriggered} from '../../redux/slices/narrativeSlice';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import './styles.scss';

const overlayImages = {
  desk: {
    desktop: require('../../assets/img/overlay-desk.png'),
    mobile: require('../../assets/img/overlay-mobile-desk.png')
  },
  notifications: {
    desktop: require('../../assets/img/overlay-notifications.png'),
    mobile: require('../../assets/img/overlay-mobile-notifications.png')
  },
  options: {
    desktop: require('../../assets/img/overlay-options.png'),
    mobile: require('../../assets/img/overlay-mobile-options.png')
  },
  back: {
    desktop: require('../../assets/img/overlay-back.png'),
    mobile: require('../../assets/img/overlay-mobile-back.png')
  },
  audio: {
    desktop: require('../../assets/img/overlay-audio.png'),
    mobile: require('../../assets/img/overlay-mobile-audio.png')
  },
}

const Overlay = ({id, label, timeOut}) => {
  const dispatch = useDispatch();
  const timerRef = useRef(null);
  const mobileView = useMobileView();

  const closeOverlay = useCallback(() => {
    clearTimeout(timerRef.current);
    dispatch(setOverlayTriggered({overlayId: id}));
  }, [dispatch, id]);

  useEffect(() => {
    if(timeOut) {
      timerRef.current = setTimeout(closeOverlay, timeOut * 1000);
    }
    return () => clearTimeout(timerRef.current);
  }, [closeOverlay, id, timeOut]);

  const renderTime = ({ remainingTime }) => {
    return (
      <div className="timer">
        <div className="value">{remainingTime}</div>
      </div>
    );
  };

  return(<div className="overlay-wrapper">
    <img src={mobileView ? overlayImages[label].mobile : overlayImages[label].desktop} alt={label} />
    <div className="overlayTimer">
      <button onClick={closeOverlay}>Continue</button>
      <CountdownCircleTimer
            key={id}
            isPlaying
            duration={timeOut}
            colors={["#f99633"]}
            colorsTime={[10]}
            strokeWidth={3}
            size={50}
            trailColor={"#FFFFFF"}
          >
            {renderTime}
      </CountdownCircleTimer>
      </div>
  </div>);
}

export default Overlay;
 