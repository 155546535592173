import './styles.scss';

const Notification = props => {
  const {type, text, position, backgroundColour, onClick} = props;
  const iconMap = {
    email: require('../../../assets/img/icon-email.png'),
    call: require('../../../assets/img/icon-call.png'),
    message: require('../../../assets/img/icon-message.png'),
    default: require('../../../assets/img/icon-email.png'),
  };

  const notificationBackground = backgroundColour ?? 'rgba(255,255,255,.5)';
  const imageSrc = Object.keys(iconMap).includes(type) ? iconMap[type] : iconMap.default;

  return (
    <div className="notification-wrapper" onClick={onClick} style={{top: position.y, left: position.x, backgroundColor: notificationBackground,}}>
      <img src={imageSrc} alt={type} />
      <p>{text}</p>
    </div>
  );
};

export default Notification;